
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { TFAService } from "src/app/common/service/tfa.service";

@Injectable()
export class TFARepository {

    constructor(private tfaService: TFAService) {

    }
    
    public enableTFA(secret: string): Observable<any> {
        return this.tfaService.enable2FA(secret);
    }

    public disableTFA(): Observable<any> {
        return this.tfaService.disable2FA();
    }

    public verify2faOTP(otpCode): Observable<any> {
        return this.tfaService.verifyToken(otpCode);
    }

    public get2FAStatus(): Observable<any> {
        return this.tfaService.get2FAStatus();
    }
}