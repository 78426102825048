
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, HostListener, ChangeDetectionStrategy, ChangeDetectorRef, Inject, OnDestroy } from "@angular/core";
import { CommonUtil } from "../../../common/utils/common.utils";
import { Store } from "@ngrx/store";
import { UserProfile } from "../../../shared/models";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Broadcaster } from "src/app/common/providers/broadcaster.service";
import { ContactRootState } from "src/app/contacts/store";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Contact } from "src/app/common/models";
import { ToastService } from "src/app/common/service/tost.service";
import { BroadcastKeys } from "src/app/common/enums/broadcast.enum";
import { ResponsiveService } from "src/app/common/providers/responsive.service";
import { ImageCroppedEvent, ImageTransform } from "ngx-image-cropper";

@Component({
    selector: "vp-contact-image-cropper-dialog",
    templateUrl: "./contact-image-cropper-dialog.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class ContactImageCropperDialogComponent implements OnDestroy {

    imageData: any;
    isFileUploded: boolean = false;
    userProfile: UserProfile = null;
    isMobileView: boolean = false;
    contactImageData: any = null;
    isContactCropper: boolean = false;
    isLoading: boolean = false;
    isIos: boolean = false;
    profileAvtarUpload = "";
    profileRemoveAvtar = "";
    private isAlive$ = new Subject<boolean>();
    showImage: boolean = false;
    showRemove: boolean = false;
    userAvatarURL: string = "";
    noAvatar: boolean = true;
    screen: string = this.responsiveService.getScreen();
    isAlive = true;
    imageChangedEvent: any = "";
    croppedImage: any = "";
    transform: ImageTransform = {};
    canvasRotation = 0;
    rotation = 0;
    containWithinAspectRatio = true;
    isExistingImage: boolean = false;


    constructor(
        private contactBroadcaster: Broadcaster,
        private changeDetection: ChangeDetectorRef,
        private appStore: Store<ContactRootState>,
        private dialogRef: MatDialogRef<ContactImageCropperDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private toastService: ToastService,
        private responsiveService: ResponsiveService
    ) {
        if (typeof device !== "undefined" && device.platform.toUpperCase() === "IOS") {
            this.isIos = true;
            this.changeDetection.markForCheck();
        }

        this.isMobileView = CommonUtil.isMobileView();
        this.contactBroadcaster.on<any>(BroadcastKeys.HIDE_CROPPER_DIALOG).pipe(takeUntil(this.isAlive$))
            .subscribe(presence => {
                this.close();
            });
        if (this.data.profileUserDetail) {
            setTimeout(() => {
                this.profileAvtarUpload = "profileAvtarUpload";
                this.profileRemoveAvtar = "profileRemoveAvtarUpload";
                this.openForProfile();
            }, 100);
        }
        if (!this.data.contactUserAvtar) {
            setTimeout(() => {
                this.profileAvtarUpload = "contactAvtarUpload";
                this.profileRemoveAvtar = "contactRemoveAvtarUpload";
                this.openForContact(this.data.contactUserAvtar);
            }, 100);

        }

        this.responsiveService.screen$
        .pipe(takeUntil(this.isAlive$))
        .subscribe(screen => {
            this.screen = screen;
            this.changeDetection.markForCheck();
        });
    }

    open(userProfile: UserProfile): void {
        this.isMobileView = CommonUtil.isMobileView();
        this.isContactCropper = false;
        this.resetCropper();
        this.userProfile = userProfile;
        if (this.userProfile && this.userProfile.userAvatar) {
            this.loadExistingCropperAvtar(this.userProfile.userAvatar);
        }
        this.show();
    }

    openForContact(avtarUrl: any, contact?: Contact) {
        this.isContactCropper = true;
        this.resetCropper();
        const avatarURL: string = this.data.avatarURL;
        if (avatarURL.startsWith("http") || avatarURL.startsWith("https")) {
            this.showImage = true;
            this.userAvatarURL = avatarURL;
            this.showRemove = true;
            this.noAvatar = false;
        } else if (avatarURL.startsWith("data:")) {
            this.contactImageData = avatarURL;
            this.loadExistingCropperAvtar(this.contactImageData);
            this.showRemove = true;
            this.noAvatar = false;
        }
        this.changeDetection.markForCheck();
        this.show();
    }

    loadExistingCropperAvtar(data: any) {
        this.isExistingImage = true;
        this.croppedImage = data;
        this.changeDetection.markForCheck();
    }

    resetCropper() {
        this.isFileUploded = false;
        this.imageData = {};
        this.contactImageData = null;
        if (this.contactImageData) {
            this.contactImageData = null;
        }
        this.changeDetection.markForCheck();
    }

    show(): void {
        setTimeout(() => {
            const canvasElement = document.querySelector("canvas");
            if (canvasElement !== null) {
                canvasElement.classList.add("disable-select");
            }
            this.changeDetection.markForCheck();
        }, 100);
    }

    removeUserAvtar() {
        if (this.isContactCropper) {
            this.contactBroadcaster.broadcast(this.profileAvtarUpload, {
                "operation": ""
            });
        }
        if (!this.isContactCropper) {
            this.contactBroadcaster.broadcast("REMOVE_AVATAR_PROFILE_USER", {
                "operation": ""
            });
        }
        this.close();
    }

    clickOutsideToClose(event): void {
        this.close();
    }

    @HostListener("document:keydown.esc", ["$event"])
    close(): void {
        this.isFileUploded = false;
        this.imageData = {};
        this.isAlive$.next(false);
        this.isAlive$.complete();
        this.dialogRef.close();
        this.changeDetection.markForCheck();
    }

    fileChangeListener($event) {
        console.log("filechange listener called...");
        this.isExistingImage = false;
        this.changeDetection.markForCheck();
        let image: HTMLImageElement = new Image();
        if (!$event.target.files) {
            return;
        }
        if ($event.target.files.length > 1) {
            this.toastService.show("FILE_LENGTH_ERROR");
            return;
        }
        let file: File = $event.target.files[0];
        if (file.size >= 2000000) {
            this.toastService.show("AVTAR_SIZE_ERR");
        } else {
            this.showImage = false;
            this.noAvatar = false;
            this.isLoading = true;
            this.changeDetection.markForCheck();
            let myReader: FileReader = new FileReader();
            myReader.onloadend = (loadEvent: any) => {
               this.imageChangedEvent = $event;
               this.isFileUploded = true;
               this.isLoading = false;
               this.changeDetection.markForCheck();
            };
            myReader.readAsDataURL(file);
        }
    }

    saveCropperImge() {
        let b64Data = this.croppedImage;
        if (this.data.profileUserDetail) {
            this.contactBroadcaster.broadcast("contactAvtarUpload", {
                "operation": b64Data
            });
        } else {
            this.contactBroadcaster.broadcast(this.profileAvtarUpload, {
                "operation": b64Data
            });
        }
        this.close();
    }

    private b64toBlob(b64Data, contentType, sliceSize?): any {
        contentType = contentType || "";
        sliceSize = sliceSize || 512;
        let byteCharacters = atob(b64Data);
        let byteArrays = [];
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            let slice = byteCharacters.slice(offset, offset + sliceSize);
            let byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            let byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        let blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    savebase64AsImageFile(blob, filename, currentObject): any {
        currentObject.contactBroadcaster.broadcast(currentObject.profileAvtarUpload, {
            "operation": blob
        });
        currentObject.close();
    }

    ngOnDestroy() {
        this.isAlive$.next(false);
        this.isAlive$.unsubscribe();
    }

    imgLoadOnError(event) {
        this.showImage = false;
        this.showRemove = false;
        this.noAvatar = true;
        this.changeDetection.markForCheck();
    }

    openForProfile() {
        this.isContactCropper = false;
        this.resetCropper();
        const avatarURL: string = this.data.contactUserAvtar;
        if (!!avatarURL && avatarURL !== null) {
            this.showImage = true;
            this.userAvatarURL = avatarURL;
            this.showRemove = true;
            this.noAvatar = false;
        } else if (avatarURL.startsWith("data:")) {
            this.contactImageData = avatarURL;
            this.loadExistingCropperAvtar(this.contactImageData);
            this.showRemove = true;
            this.noAvatar = false;
        }
        this.changeDetection.markForCheck();
        this.show();
    }

    fileChangeEvent(event: any): void {
        this.imageChangedEvent = event;
    }

    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
        this.changeDetection.markForCheck();
    }

    imageLoaded(image: HTMLImageElement) {
        // show cropper
    }

    cropperReady() {
        // cropper ready
    }

    loadImageFailed() {
        // show message
    }

    rotateLeft() {
        this.canvasRotation--;
        this.flipAfterRotate();
    }

    rotateRight() {
        this.canvasRotation++;
        this.flipAfterRotate();
    }

    private flipAfterRotate() {
        const flippedH = this.transform.flipH;
        const flippedV = this.transform.flipV;
        this.transform = {
            ...this.transform,
            flipH: flippedV,
            flipV: flippedH
        };
    }

}