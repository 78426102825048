
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { EventEmitter, Injectable } from "@angular/core";
import { Http } from "@angular/http";
import { Subject, BehaviorSubject, Subscription, Observable } from "rxjs";

@Injectable()
export class HeaderService {
  private searchBox: EventEmitter<HeaderEvent>;

  searchText$ = new Subject();
  private advanceSearch: EventEmitter<any>;

  notifications$ = new BehaviorSubject<Notification[]>([]);
  toggleDrawer$ = new Subject<boolean>();
  subscription: Subscription;

  breadcrumbs$ = new BehaviorSubject<{title: string; path: string}[]>([]);
  breadcrumbClicked$ = new Subject();
  avatarUpdated$ = new Subject();

  public onActionClicked$ = new Subject<string>();

  static ACTIONS = {
    CONTACT: {
      CONTACT_SEARCH: "contact-search",
      CONTACT_SAVED_SEARCH: "contact-saved-search"
    }
  };

  constructor(
    private http: Http
  ) {
    this.searchBox = new EventEmitter<HeaderEvent>();
    this.advanceSearch = new EventEmitter<any>();
  }

  getSearchEmitter(): EventEmitter<HeaderEvent> {
    return this.searchBox;
  }

  emitAdvanceSearch(args): void {
    this.advanceSearch.emit(args);
  }

  getAdvanceSearchEmitter(): EventEmitter<any> {
    return this.advanceSearch;
  }

  getDrawerSource(): Observable<any> {
    return this.toggleDrawer$.asObservable();
  }

  toggleDrawer(): void {
    this.toggleDrawer$.next(true);
  }

  destroy() {
    if (this.subscription)
      this.subscription.unsubscribe();
  }

  emitAction(action: string) {
    this.onActionClicked$.next(action);
  }
}

export class HeaderEvent {
  action: number;
  text: string;
  static TYPING?: number = 0;
  static ENTER?: number = 1;
}

export interface RefreshFlag {
  start: boolean;
}
