
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, OnDestroy, OnInit, ChangeDetectorRef, HostListener, NgZone } from "@angular/core";
import { SetAvailableApps, DeviceReady, OnlineStatus, SetUserProfile, SetLoginStatus } from "./actions/app";
import { Store, select } from "@ngrx/store";
import { RootState, getOnlineStatus, getIsDeviceReady, getUserProfile, getLoginStatus } from "./reducers";
import { ConfigService } from "./common/providers/config.service";
import { filter, take, merge, map, takeUntil } from "rxjs/operators";
import { of, fromEvent, Subject } from "rxjs";
import { environment } from "src/environments/environment";
import { Broadcaster } from "./common/providers/broadcaster.service";
import { ContactService } from "./common/service/contact-service";
import { LocalStorageService } from "./common/providers/storage.service";
import { AppConstants } from "./common/utils/app-constants";
import { TranslateService } from "@ngx-translate/core";
import { AuthUser } from "./common/models";
import { ToastService } from "./common/service/tost.service";
import { ContactRepository } from "./contacts/repository/contact.repository";
import { Router } from "@angular/router";
import { isNullOrUndefined } from "util";
import { BroadcastKeys } from "./common/enums/broadcast.enum";
import { CommonUtil } from "./common/utils/common.utils";
import { AuthService } from "./common/providers/auth.service";

@Component({
  selector: "vp-app",
  templateUrl: "./app.component.html"
})
export class AppComponent implements OnInit, OnDestroy {

  isLoggedIn: boolean = false;
  routedFolderTitle: string;
  queryParams: any;
  private isAlive$ = new Subject<boolean>();
  selectedLanguage: string = " ";
  currentRoute: string = "contacts";
  hideHeader: boolean = false;

  constructor(
    private rootStore: Store<RootState>,
    public configService: ConfigService,
    private broadcaster: Broadcaster,
    private changeDetectionRef: ChangeDetectorRef,
    private contactService: ContactService,
    private localStorage: LocalStorageService,
    private translate: TranslateService,
    private toastService: ToastService,
    private contactRepository: ContactRepository,
    private ngZone: NgZone,
    private router: Router,
    private authService: AuthService
  ) {
    this.translate.use(this.selectedLanguage);
    this.translate.reloadLang(this.selectedLanguage);
    this.configService.language = this.selectedLanguage;

    if (window.location !== window.parent.location) {
      this.hideHeader = true;
    }

    this.changeDetectionRef.markForCheck();
  }

  ngOnInit() {
    document.addEventListener("deviceready", this.onDeviceReady.bind(this));
    this.checkInternetConnection();
    if (localStorage.getItem("federatedApps") !== null) {
      const federatedApps = JSON.parse(localStorage.getItem("federatedApps"));
      this.rootStore.dispatch(new SetAvailableApps(federatedApps));
    }
    this.configService.loadConfig();
    this.rootStore.select(getOnlineStatus).pipe(filter(v => !!v), takeUntil(this.isAlive$)).subscribe(isOnline => {
      this.configService.getConfig().pipe(takeUntil(this.isAlive$)).subscribe((res: any) => {
        res = JSON.parse(res._body);
        localStorage.setItem("config", JSON.stringify(res));
        if (res.URLS) {
          this.configService.URLS = res.URLS;
          this.configService.set("URLS", res.URLS);
        }
        if (res.publicVncDirectoryUrl) {
          localStorage.setItem("publicVncDirectoryUrl", res.publicVncDirectoryUrl);
          this.configService.set("publicVncDirectoryUrl", res.publicVncDirectoryUrl);
        }
        if (res.avatarServiceUrl) {
          localStorage.setItem("avatarServiceUrl", res.avatarServiceUrl);
          this.configService.set("avatarServiceUrl", res.avatarServiceUrl);
        }

        if (res.two_factor_authentication) {
          this.configService.two_factor_authentication = res.two_factor_authentication;
          localStorage.setItem("twoFactorAuthentication", res.two_factor_authentication ? "true" : "false");
        } else {
          this.configService.two_factor_authentication = false;
          localStorage.setItem("twoFactorAuthentication", "false");
        }
      });
    });
    this.rootStore.select(getIsDeviceReady).pipe(filter(isReady => isReady), takeUntil(this.isAlive$)).subscribe(isReady => {
      if (typeof navigator !== "undefined" && navigator.splashscreen) {
        navigator.splashscreen.hide();
        this.changeDetectionRef.detectChanges();
      }
      if (typeof Keyboard !== "undefined") {
        Keyboard.shrinkView(true);
      }
      document.addEventListener("online", this.handleConnectionChange.bind(this), false);
      document.addEventListener("offline", this.handleConnectionChange.bind(this), false);

      document.addEventListener("pause", () => {
        console.log("pause => background");
        window.appInBackground = true;
      });

      document.addEventListener("resume", () => {
        console.log("resume => not in background");
        window.appInBackground = false;
      });

    });
    this.rootStore.pipe(select(getOnlineStatus), filter(v => !!v), takeUntil(this.isAlive$)).subscribe(isOnline => {
      console.log("[appcomponent][init] getOnlineStatus", isOnline);
      // this.isLoggedIn = isOnline;
      this.changeDetectionRef.markForCheck();
      this.loadProfile();
    });
    this.rootStore.select(getLoginStatus).pipe(takeUntil(this.isAlive$)).subscribe( isLoggedIn => {
      console.log("[Loggedin User] app.component.ts", isLoggedIn, navigator.onLine);
      if (!navigator.onLine) {
        this.loadProfile();
        let storedUserRaw = localStorage.getItem("profileUser");
        try {
          const storedUser = JSON.parse(storedUserRaw);
          if (!!storedUser.id) {
            this.isLoggedIn = true;
            this.changeDetectionRef.markForCheck();
          }

        } catch (error) {
          console.log("[Loggedin User] loadStoredProfile error: ", error);
        }
      } else {

        this.isLoggedIn = isLoggedIn;
        this.changeDetectionRef.markForCheck();
        if (this.isLoggedIn) {
          if (CommonUtil.isOnAndroid()) {
            this.firebaseSetup();
          }
        }
      }
    });

  }

  onDeviceReady() {
    console.log("[App Component] Device Ready");
    this.rootStore.dispatch(new DeviceReady());
    if (window["cordova"].InAppBrowser) {
      window.open = window["cordova"].InAppBrowser.open;
    }
    this.handleBackButton();
    if (typeof Keyboard !== "undefined") {
      try {
        Keyboard.shrinkView(true);
      } catch (ex) {

      }
    }
    if (CommonUtil.isOnAndroid()) {
      CommonUtil.requestPermissions();
    }
    if (CommonUtil.isOnMobileDevice() && !CommonUtil.isOnIpad()) {
      screen.orientation.lock("portrait");
    } else {
      screen.orientation.unlock();
    }

    if (environment.isCordova) {
      console.log("[AppComponent][onDeviceReady] StatusBar.show");
      StatusBar.show();
      if (CommonUtil.isOnIOS()) {
        StatusBar.backgroundColorByHexString("#0070BC");
        StatusBar.styleLightContent();
      }
    }
  }

  ngOnDestroy() {
    this.isAlive$.next(false);
    this.isAlive$.unsubscribe();
  }

  private checkInternetConnection() {
    this.rootStore.dispatch(new OnlineStatus(navigator.onLine));
    if (!environment.isCordova) {
      window.addEventListener("online", this.handleConnectionChange.bind(this));
      window.addEventListener("offline", this.handleConnectionChange.bind(this));
    }

  }

  private handleConnectionChange(event): void {
    console.log("NETWORK connection status is now: ", event);
    this.rootStore.dispatch(new OnlineStatus(navigator.onLine));
  }

  loadProfile(): void {
    const isCordovaOrElectron = environment.isCordova || environment.isElectron;
    let storeSortType = localStorage.getItem(AppConstants.SORT_TYPE);
    if (!!storeSortType && storeSortType !== null) {
      if (storeSortType === "asc" || storeSortType === "desc") {
        storeSortType = "first_name";
      }
      localStorage.setItem(AppConstants.SORT_TYPE, storeSortType);
    } else {
      localStorage.setItem(AppConstants.SORT_TYPE, "first_name");
    }
    console.log("app.cpmponent going to load profile: ");
    this.contactService.getAuthUser().pipe(take(1)).subscribe((res: AuthUser) => {
      console.log("[loadProfile] AppComponent: ", res);
      if (res !== null && !!res) {
        this.rootStore.dispatch(new SetLoginStatus(true));
        this.isLoggedIn = true;
        this.changeDetectionRef.markForCheck();
        const user = res;
        this.loadLanguage(user.language);
        this.localStorage.setItem(AppConstants.PROFILE_USER, JSON.stringify(user));
        this.rootStore.dispatch(new SetUserProfile(user));
        this.contactRepository.poppulateAuthUserAvatar(user, false).subscribe(success => {
          if (success) {
            this.localStorage.setItem(AppConstants.PROFILE_USER, JSON.stringify(user));
            this.rootStore.dispatch(new SetUserProfile(user));
          }
        });
        this.authService.getProfile().subscribe((data: any) => {
          if (data && data.user) {
            console.log("[AppComponent][getProfile] response", data);
            localStorage.setItem("loggedInUser", JSON.stringify(data.user));
          }
        });
        this.contactService.getMyProducts()
        .subscribe((data: any) => {
          console.log("[AppComponent][getMyProducts] response", data);
          if (data && data.products) {
             localStorage.setItem("federatedApps", JSON.stringify(data.products));
             this.rootStore.dispatch(new SetAvailableApps(data.products));
          }
        });
      } else {
        this.isLoggedIn = false;
        this.rootStore.dispatch(new SetLoginStatus(false));
        this.changeDetectionRef.markForCheck();
        if (!isCordovaOrElectron) {
          window.location.href = this.configService.API_URL + "/api/login";
        } else {
          this.configService.loginIframe();
        }
      }
    }, error => {
      console.log("[Error][loadProfile][app.component]", error);
      this.rootStore.dispatch(new SetLoginStatus(false));
      if (!isCordovaOrElectron) {
        window.location.href = this.configService.API_URL + "/api/login";
      } else {
        this.configService.loginIframe();
      }
    });
  }

  public loadLanguage(selectedLanguage: string): void {
    if (!selectedLanguage) {
      selectedLanguage = "en";
    }
    this.translate.use(selectedLanguage);
    this.translate.reloadLang(selectedLanguage);
    this.configService.language = selectedLanguage;
  }

  @HostListener("document:click", ["$event"])
  documentClickEventHandler(event: any) {
    if (!environment.isCordova) {
      return;
    }
    let url: string;
    if (event.target.classList.contains("open-new-window") || event.target.classList.contains("ql-preview")) {
      url = event.target.href;
    }
    if (url) {
      event.stopPropagation();
      event.preventDefault();
      console.log("[documentClickEventHandler]", event);
      if (device.platform === "iOS") {
        window.open(url, "_system");
      } else if (device.platform === "Android") {
        navigator.app.loadUrl(url, {
          openExternal: true
        });
      }
    }
  }

  @HostListener("window:message", ["$event"])
  windowMessageEventHandler(event: MessageEvent) {
    const eventData = event.data;

    console.log("[AppComponent] windowMessageEventHandler ", eventData);
    if (eventData.source && eventData.source === "@devtools-page") {
      // Chrome Redux-devtools extension message
      return;
    }

    if (eventData && eventData.type === "GO_TO_SERVER_URL_PAGE") {
      console.log("[AppComponent] GO_TO_SERVER_URL_PAGE", eventData);
      this.ngZone.run(() => {
        this.configService.selectedServer = false;
        this.changeDetectionRef.markForCheck();
      });
      if (document.querySelector("#loginIframe") !== null) {
        document.querySelector("#loginIframe").remove();
      }
    } else if (eventData && eventData.type === "VNC_PORTAL_POST_MESSAGE") {
      console.log("[AppComponent] login postback update: ", eventData);
      this.configService.hideLoginIframe();
      console.log("[windowMessageEventHandler]: ", eventData);
      let unverifiedToken = localStorage.getItem("unVerifiedToken");
      if (unverifiedToken) {
        localStorage.removeItem("unVerifiedToken");
        this.configService.hideTfaOtpIframe();
      }
      localStorage.setItem("token", eventData.token);
      this.rootStore.dispatch(new OnlineStatus(navigator.onLine));
      this.ngZone.run(() => {
        this.rootStore.pipe(select(getOnlineStatus), filter(v => !!v), takeUntil(this.isAlive$)).subscribe(isOnline => {
          this.loadProfile();
          this.router.navigate(["/"], { skipLocationChange: true, replaceUrl: true });
          this.changeDetectionRef.markForCheck();
        });
      });
    } else if (eventData && eventData.type === "TFA_OTP_VERIFICATION") {
      if (eventData.token && eventData.token.trim().length > 0) {
        localStorage.setItem("unVerifiedToken", eventData.token);
        this.configService.hideLoginIframe();
        this.configService.tfaOtpIframe();
        return;
      }
    } else if (eventData && eventData.type === "GO_TO_LOGIN_SCREEN") {
      this.configService.hideTfaOtpIframe();
      this.configService.loginIframe();
      return;
    } else if (eventData && !isNullOrUndefined(eventData.link) && (typeof eventData.link) !== "function") {
      console.log("[AppComponent] data.link snippet: ", event, eventData.link);
      if (device.platform === "iOS") {
        window.open(eventData.link, "_system");
      } else {
        navigator.app.loadUrl(eventData.link, {
          openExternal: true
        });
      }
    }
    this.changeDetectionRef.markForCheck();
  }

  handleBackButton(): void{
    document.addEventListener("backbutton", (e) => {
      if (document.querySelector("#logoutIframe") !== null || document.querySelector("#loginIframe") !== null) {
        navigator.app.exitApp();
      } else if (document.querySelector("#tfaOtpIframe") !== null) {
        this.configService.hideTfaOtpIframe();
        this.configService.loginIframe();
      } else if (document.querySelector("vp-confirmation-dialog") !== null) {
        this.broadcaster.broadcast(BroadcastKeys.HIDE_CONFIRMATION_DIALOG);
        console.log("[handleBackButton]", BroadcastKeys.HIDE_CONFIRMATION_DIALOG);
      } else if (document.querySelector("vp-contact-image-cropper-dialog") !== null) {
        this.broadcaster.broadcast(BroadcastKeys.HIDE_CROPPER_DIALOG);
        console.log("[handleBackButton]", BroadcastKeys.HIDE_CROPPER_DIALOG);
      } else if (document.querySelector("vp-create-contactlus-dialog") !== null) {
        this.broadcaster.broadcast(BroadcastKeys.HIDE_CREATE_CONTACT_DIALOG);
        console.log("[handleBackButton]", BroadcastKeys.HIDE_CREATE_CONTACT_DIALOG);
      } else if (document.querySelector("vp-create-contact-group-dialog") !== null) {
        this.broadcaster.broadcast(BroadcastKeys.HIDE_CONTACT_GROUP_CREATE_DIALOG);
        console.log("[handleBackButton]", BroadcastKeys.HIDE_CONTACT_GROUP_CREATE_DIALOG);
      } else if (document.querySelector("vp-apps-dialog") !== null) {
        this.broadcaster.broadcast(BroadcastKeys.HIDE_APP_DIALOG);
        console.log("[handleBackButton]", BroadcastKeys.HIDE_APP_DIALOG);
      } else if (document.querySelector("vp-tfa-settings") !== null) {
        this.broadcaster.broadcast(BroadcastKeys.HIDE_TFA_SETTINGS_DIALOG);
        console.log("[handleBackButton]", BroadcastKeys.HIDE_TFA_SETTINGS_DIALOG);
      } else if (document.querySelector("vp-contact-settings-dialog") !== null) {
        this.broadcaster.broadcast(BroadcastKeys.HIDE_CONTACT_SETTINGS_DIALOG);
        console.log("[handleBackButton]", BroadcastKeys.HIDE_CONTACT_SETTINGS_DIALOG);
      } else if (document.querySelector("vp-about-dialog") !== null) {
        this.broadcaster.broadcast(BroadcastKeys.HIDE_VERSION_DIALOG);
        console.log("[handleBackButton]", BroadcastKeys.HIDE_VERSION_DIALOG);
      } else if (document.querySelector("vp-help-faq-dialog") !== null) {
        this.broadcaster.broadcast(BroadcastKeys.HIDE_FAQ_DIALOG);
        console.log("[handleBackButton]", BroadcastKeys.HIDE_FAQ_DIALOG);
      } else if (document.querySelector("vp-servicedesk-dialog") !== null) {
        this.broadcaster.broadcast(BroadcastKeys.HIDE_SERVICEDESK);
        console.log("[handleBackButton]", BroadcastKeys.HIDE_SERVICEDESK);
      } else if (document.querySelector("vp-legal-notice-dialog") !== null) {
        this.broadcaster.broadcast(BroadcastKeys.HIDE_LEGAL_DIALOG);
        console.log("[handleBackButton]", BroadcastKeys.HIDE_LEGAL_DIALOG);
      } else if (document.querySelector("vp-contact-recent-actvity-dialog") !== null) {
        this.broadcaster.broadcast(BroadcastKeys.HIDE_RECENT_ACTIVITY_DIALOG);
        console.log("[handleBackButton]", BroadcastKeys.HIDE_RECENT_ACTIVITY_DIALOG);
      } else if (document.querySelector("vp-contact-detail-dialog") !== null) {
        this.broadcaster.broadcast(BroadcastKeys.HIDE_CONTACT_DETAIL_DIALOG);
        console.log("[handleBackButton]", BroadcastKeys.HIDE_CONTACT_DETAIL_DIALOG);
      } else if (document.querySelector("vp-contact-group-detail-dialog") !== null) {
        this.broadcaster.broadcast(BroadcastKeys.HIDE_CONTACT_GROUP_DETAIL_DIALOG);
        console.log("[handleBackButton]", BroadcastKeys.HIDE_CONTACT_GROUP_DETAIL_DIALOG);
      } else if (document.querySelector("vp-avatar-dialog") !== null) {
        this.broadcaster.broadcast(BroadcastKeys.HIDE_PROFILE_DIALOG);
        console.log("[handleBackButton]", BroadcastKeys.HIDE_PROFILE_DIALOG);
      } else if (document.querySelector("vp-contact-undo-changes-dialog") !== null) {
        this.broadcaster.broadcast(BroadcastKeys.HIDE_UNDO_CHANGES_DIALOG);
        console.log("[handleBackButton]", BroadcastKeys.HIDE_UNDO_CHANGES_DIALOG);
      } else if (document.querySelector("vp-contact-import-dialog") !== null) {
        this.broadcaster.broadcast(BroadcastKeys.HIDE_IMPORT_CONTACT_DIALOG);
        console.log("[handleBackButton]", BroadcastKeys.HIDE_IMPORT_CONTACT_DIALOG);
      } else if (document.querySelector(".is-mobile-search") !== null) {
        this.broadcaster.broadcast(BroadcastKeys.HIDE_SEARCH_CONTACT_COMPONENT);
        console.log("[handleBackButton]", BroadcastKeys.HIDE_SEARCH_CONTACT_COMPONENT);
      } else if (document.querySelector("vp-mobile-about-dialog") !== null) {
        this.broadcaster.broadcast(BroadcastKeys.HIDE_MOBILE_ABOUT_DIALOG);
        console.log("[handleBackButton]", BroadcastKeys.HIDE_MOBILE_ABOUT_DIALOG);
      } else if (document.querySelector("vp-mobile-actions-dialog") !== null) {
        this.broadcaster.broadcast(BroadcastKeys.HIDE_MOBILE_ACTIONS_DIALOG);
        console.log("[handleBackButton]", BroadcastKeys.HIDE_MOBILE_ACTIONS_DIALOG);
      } else if (document.querySelector("vp-share-contact-email") !== null) {
        this.broadcaster.broadcast(BroadcastKeys.HIDE_SHARE_CONTACT_EMAIL);
        console.log("[handleBackButton]", BroadcastKeys.HIDE_SHARE_CONTACT_EMAIL);
      } else if (document.querySelector("vp-tag-contact-dialog") !== null) {
        this.broadcaster.broadcast(BroadcastKeys.HIDE_CONTACT_TAG_DIALOG);
        console.log("[handleBackButton]", BroadcastKeys.HIDE_CONTACT_TAG_DIALOG);
      } else if (document.querySelector("vp-save-search-contact-dialog") !== null) {
        this.broadcaster.broadcast(BroadcastKeys.HIDE_CONTACT_SAVE_SEARCH_DIALOG);
        console.log("[handleBackButton]", BroadcastKeys.HIDE_CONTACT_SAVE_SEARCH_DIALOG);
      } else if (document.querySelector("vp-list-contact-dialog") !== null) {
        this.broadcaster.broadcast(BroadcastKeys.HIDE_CONTACT_LIST_DIALOG);
        console.log("[handleBackButton]", BroadcastKeys.HIDE_CONTACT_LIST_DIALOG);
      } else if (document.querySelector("vp-contact-lists-dialog") !== null) {
        this.broadcaster.broadcast(BroadcastKeys.HIDE_CONTACT_LISTS_DAILOG);
        console.log("[handleBackButton]", BroadcastKeys.HIDE_CONTACT_LISTS_DAILOG);
      } else if (document.querySelector("vp-contact-lists-operation-dialog") !== null) {
        this.broadcaster.broadcast(BroadcastKeys.HIDE_CONTACT_LISTS_OPERATION_DAILOG);
        console.log("[handleBackButton]", BroadcastKeys.HIDE_CONTACT_LISTS_OPERATION_DAILOG);
      } else if (document.querySelector("vp-contact-add-to-list-dialog") !== null) {
        this.broadcaster.broadcast(BroadcastKeys.HIDE_ADD_TO_LISTS_DAILOG);
        console.log("[handleBackButton]", BroadcastKeys.HIDE_ADD_TO_LISTS_DAILOG);
      } else if (document.querySelector("vp-contact-add-to-tag-dialog") !== null) {
        this.broadcaster.broadcast(BroadcastKeys.HIDE_ADD_TO_TAGS_DAILOG);
        console.log("[handleBackButton]", BroadcastKeys.HIDE_ADD_TO_TAGS_DAILOG);
      } else if (document.querySelector("vp-contact-tags-dialog") !== null) {
        this.broadcaster.broadcast(BroadcastKeys.HIDE_CONTACT_TAGS_DAILOG);
        console.log("[handleBackButton]", BroadcastKeys.HIDE_CONTACT_TAGS_DAILOG);
      } else if (document.querySelector("vp-search-in-apps-dialog") !== null) {
        this.broadcaster.broadcast(BroadcastKeys.HIDE_SEACH_IN_APPS_DIALOG);
        console.log("[handleBackButton]", BroadcastKeys.HIDE_SEACH_IN_APPS_DIALOG);
      } else if (document.querySelector("vp-search-in-lists-dialog") !== null) {
        this.broadcaster.broadcast(BroadcastKeys.HIDE_SEACH_IN_LISTS_DIALOG);
        console.log("[handleBackButton]", BroadcastKeys.HIDE_SEACH_IN_LISTS_DIALOG);
      } else if (document.querySelector("vp-advance-search-dialog") !== null) {
        this.broadcaster.broadcast(BroadcastKeys.HIDE_ADVANCE_SEARCH_DIALOG);
        console.log("[handleBackButton]", BroadcastKeys.HIDE_ADVANCE_SEARCH_DIALOG);
      } else if (document.querySelector("vp-mobile-contact-group-detail-dialog") !== null) {
        this.broadcaster.broadcast(BroadcastKeys.HIDE_MOBILE_CONTACT_GROUP_DETAIL_DIALOG);
        console.log("[handleBackButton]", BroadcastKeys.HIDE_MOBILE_CONTACT_GROUP_DETAIL_DIALOG);
      } else if (document.querySelector("vp-contact-group-dialog") !== null) {
        this.broadcaster.broadcast(BroadcastKeys.HIDE_CONTACT_GROUP_DIALOG);
        console.log("[handleBackButton]", BroadcastKeys.HIDE_CONTACT_GROUP_DIALOG);
      } else if (document.querySelector("vp-save-search-dialog") !== null) {
        this.broadcaster.broadcast(BroadcastKeys.HIDE_SAVE_SEARCH_DAILOG);
        console.log("[handleBackButton]", BroadcastKeys.HIDE_SAVE_SEARCH_DAILOG);
      } else if (document.querySelector("vp-advance-search-component") !== null) {
        this.broadcaster.broadcast(BroadcastKeys.HIDE_SAVE_SEARCH_DAILOG_COMPONENT);
        console.log("[handleBackButton]", BroadcastKeys.HIDE_SAVE_SEARCH_DAILOG_COMPONENT);
      }
      setTimeout(() => {
        this.changeDetectionRef.markForCheck();
      }, 20);
    }, false);
  }

  private firebaseSetup() {
    console.log("[app.component][firebaseSetup]");
    window.FirebasePlugin.onTokenRefresh(token => {
      console.log("[app.component]Firebase new token", token);
      // register in api
      const oldtoken = localStorage.getItem("fcmtoken");
      if (token !== oldtoken) {
        setTimeout(() => {

          this.contactService.pushFcmToken(token).pipe(take(1)).subscribe(res => {
            console.log("[app.component]Firebase new token stored: ", res);
            localStorage.setItem("fcmtoken", token);
          });

        }, 1000);
      }

    }, error => {
      console.error("[RootComponent] Firebase onTokenRefresh error", error);
    });

    window.FirebasePlugin.onNotificationReceived(($event) => {
      console.log("[app.component][FirebasePlugin][onNotificationReceived1][notification]", $event);

      try {
        let notificationData = JSON.parse($event.data);
        console.log("[app.component][FirebasePlugin][onNotificationReceived1][notification] parsed: ", notificationData);
        const contact = (!!notificationData && !!notificationData.contact) ? JSON.parse(notificationData.contact) : null;
        this.localStorage.setItem("updatedContact", JSON.stringify(contact));
        console.log("[app.component][FirebasePlugin][onNotificationReceived1][notification] parsedContact: ", contact);
        console.log("[app.component][FirebasePlugin][onNotificationReceived1][notification] window: ", window);
        if (!!contact) {
          if (notificationData.fcm_action === "contact_delete") {
            this.contactRepository.deleteContactFromDB(contact.id).pipe(take(1)).subscribe(() => {
              if (!window.appInBackground) {
                console.log("[app.component][FirebasePlugin][foreground] need to refresh: ", notificationData);
                this.changeDetectionRef.markForCheck();
                this.broadcaster.broadcast(BroadcastKeys.RESET_CONTACT_LIST);
              }
            });
          }
          if (notificationData.fcm_action === "contact_create") {
            this.contactRepository.createContactsInDB([contact]).pipe(take(1)).subscribe(() => {
              setTimeout(() => {
                console.log("[app.component][FirebasePlugin][foreground] need to refresh: ", notificationData);
                this.broadcaster.broadcast(BroadcastKeys.LOAD_CONTACTS_FROM_STORE, "fcm");
                this.broadcaster.broadcast("UPDATE_CONTACT_ITEM");
                this.changeDetectionRef.markForCheck();
              }, 350);
            });
          }
          if (notificationData.fcm_action === "contact_update") {
            this.contactRepository.updateContactsInDB([contact]).pipe(take(1)).subscribe(() => {
              setTimeout(() => {
                console.log("[app.component][FirebasePlugin][foreground] need to refresh: ", notificationData);
                this.broadcaster.broadcast(BroadcastKeys.LOAD_CONTACTS_FROM_STORE, "fcm");
                this.broadcaster.broadcast("UPDATE_CONTACT_ITEM");
                this.changeDetectionRef.markForCheck();
              }, 350);
            });
          }

          // fcm_action "contact_update"
          // fcm_action "contact_delete"
          // fcm_action "contact_create"

        }
      } catch (error) {
        console.error("[app.component][FirebasePlugin][onNotificationReceived1] failed to parse: ", error);
      }

    });
  }
}
