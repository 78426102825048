
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, NgZone, ChangeDetectorRef, Inject } from "@angular/core";
import { Subject } from "rxjs";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Broadcaster } from "src/app/common/providers/broadcaster.service";
import { BroadcastKeys } from "src/app/common/enums/broadcast.enum";
import { takeUntil, take } from "rxjs/operators";
import { AppConstants } from "src/app/common/utils/app-constants";
import { ContactRepository } from "src/app/contacts/repository/contact.repository";
import { ContactRootState, getContactFolders, getContactTags, getContacts, getListContacts, getTagContacts, getGroupContacts, getTrashContacts } from "../../store";
import { RootState } from "src/app/reducers";
import { Store } from "@ngrx/store";
import { ContactFolder } from "../../models/create-folder.model";
import { ContactTag } from "../../models/contact-tag.model";
import { TranslateService } from "@ngx-translate/core";
import { Contact } from "src/app/common/models";
import { ActivatedRoute } from "@angular/router";

@Component({
    selector: "vp-contact-export-dialog",
    templateUrl: "./export-contact-dialog.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExportContactDialogComponent implements OnInit, OnDestroy {

    private isAlive$ = new Subject<boolean>();
    contact_filter: any;
    contact_type: any;
    export_type: any;
    contactLists: ContactFolder[] = [];
    contactTags: ContactTag[] = [];
    selectedGroupType: string = null;
    contacts: Contact[] = [];
    searchType: string = "";
    disabledSelected: boolean = true;
    hideContactOption: boolean = false;
    constructor(
        public dialogRef: MatDialogRef<ExportContactDialogComponent>,
        private broadcaster: Broadcaster,
        private ngZone: NgZone,
        private changeDetectionRef: ChangeDetectorRef,
        private contactRepository: ContactRepository,
        private appStore: Store<ContactRootState | RootState>,
        private translateService: TranslateService,
        private activatedRoute: ActivatedRoute,
        @Inject(MAT_DIALOG_DATA) public data: any,

    ) {
      this.export_type = "csv";
      this.contact_filter = "all";
      this.contact_type = "contacts";
      this.changeDetectionRef.markForCheck();
      if(this.data && this.data.hideContactOption){
        this.hideContactOption = this.data.hideContactOption;
      }
      if (this.data && this.data.filterType) {
        this.contact_filter = this.data.filterType;
      }
      if (this.data && this.data.contactName) {
        this.contact_type = this.data.contactName;
      }

    }

    ngOnInit(): void {
        this.broadcaster.on<any>(BroadcastKeys.HIDE_EXPORT_CONTACT_DIALOG).pipe(takeUntil(this.isAlive$)).subscribe(res => {
            this.ngZone.run(() => {
                this.close();
            });
        });
        const type = this.contactRepository.getCurrentRouteType();
        this.activatedRoute.queryParams.pipe(take(1)).subscribe((params: any) => {
            if (params.searchType) {
                this.searchType = params.searchType;
            }
        });
        this.appStore.select(getContactFolders).pipe(takeUntil(this.isAlive$)).subscribe((contactFolders: ContactFolder[]) => {
            this.contactLists = contactFolders;
            this.changeDetectionRef.markForCheck();
        });
        this.appStore.select(getContactTags).pipe(takeUntil(this.isAlive$)).subscribe((contactTags: ContactTag[]) => {
            this.contactTags = contactTags;
            this.changeDetectionRef.markForCheck();
        });
        this.appStore.select(getContacts).pipe(takeUntil(this.isAlive$)).subscribe((contacts: Contact[]) => {
            const routeId = this.contactRepository.getCurrentRouteId();
            if ((routeId === AppConstants.SEARCH_TYPE && this.searchType === "contact") || routeId === AppConstants.CONTACTS_ALL || routeId === AppConstants.GLOBAL_CONTACTS) {
                if (!!contacts && contacts.length > 0) {
                    const withoutDeleteContact = contacts.filter(c => !c.deleted_at);
                    console.log("[getContacts]: ", withoutDeleteContact);
                    this.contacts = withoutDeleteContact;
                    this.changeDetectionRef.markForCheck();
                } else if (!!contacts && contacts.length === 0) {
                    this.contacts = [];
                    this.changeDetectionRef.markForCheck();
                }
            }
        });
        this.appStore.select(getListContacts).pipe(takeUntil(this.isAlive$)).subscribe((contacts: Contact[]) => {
            const type = this.contactRepository.getCurrentRouteType();
            if (type === AppConstants.LIST) {
                if (!!contacts && contacts.length > 0) {
                    const withoutDeleteContact = contacts.filter(c => !c.deleted_at);
                    console.log("[getListContacts]: ", withoutDeleteContact);
                    this.contacts = withoutDeleteContact;
                    this.changeDetectionRef.markForCheck();
                } else if (!!contacts && contacts.length === 0) {
                    this.contacts = [];
                    this.changeDetectionRef.markForCheck();
                }
            }
        });
        this.appStore.select(getTagContacts).pipe(takeUntil(this.isAlive$)).subscribe((contacts: Contact[]) => {
            const type = this.contactRepository.getCurrentRouteType();
            if (type === AppConstants.TAG) {
                if (!!contacts && contacts.length > 0) {
                    const withoutDeleteContact = contacts.filter(c => !c.deleted_at);
                    console.log("[getTagContacts]: ", withoutDeleteContact);
                    this.contacts = withoutDeleteContact;
                    this.changeDetectionRef.markForCheck();
                } else if (!!contacts && contacts.length === 0) {
                    this.contacts = [];
                    this.changeDetectionRef.markForCheck();
                }
            }
        });
        this.appStore.select(getGroupContacts).pipe(takeUntil(this.isAlive$)).subscribe((contacts: Contact[]) => {
            if ((type === AppConstants.SEARCH_TYPE && this.searchType === "contactGroup") || type === AppConstants.CONTACT_GROUP) {
                this.disabledSelected = true;
            }
        });
        if (type === AppConstants.TRASH_CONTACT || type === AppConstants.FREQUENTLY_CONTACTED || type === AppConstants.DUPLICATE_CONTACT) {
            this.disabledSelected = true;
        }
        setTimeout(() => {
            const checkedContacts = this.contacts.filter(c => c.isChecked);
            if (!!checkedContacts && checkedContacts.length > 0) {
                this.disabledSelected = false;
            } else {
                this.disabledSelected = true;
            }
            this.changeDetectionRef.markForCheck();
        }, 500);
    }

    close(): void {
        this.dialogRef.close();
    }

    exportContacts(): void {
        let resultName: string | undefined = undefined;
        if (this.contact_type !== "contacts") {
            const allArray = [...this.contactLists, ...this.contactTags];
            const foundObject = allArray.find(obj => obj.id === this.contact_type);
            resultName = foundObject ? foundObject.name : undefined;
        }
        if (this.contact_filter === "selected") {
            resultName = "selected_contacts";
        }
        let filter: string = "";
        if (this.contact_type === 'global contacts') {
            filter = '?global=1';
            if (this.selectedGroupType !== null) {
                if (this.selectedGroupType === AppConstants.LIST) {
                    filter = "?global=1&contact_list=" + this.contact_type;
                } else if (this.selectedGroupType === AppConstants.TAG) {
                    filter = "?global=1&tags=" + this.contact_type;
                }
            }
        }
        else if (this.contact_type === 'contact groups') {
            filter = '?contact_group=1';
            if (this.selectedGroupType !== null) {
                if (this.selectedGroupType === AppConstants.LIST) {
                    filter = "?contact_group=1&contact_list=" + this.contact_type;
                } else if (this.selectedGroupType === AppConstants.TAG) {
                    filter = "?contact_group=1&tags=" + this.contact_type;
                }
            }
        }
        else if (this.contact_filter === "all" && this.contact_type !== "all_contacts") {
            filter = "?global=0&all=1";
            if (this.selectedGroupType !== null) {
                if (this.selectedGroupType === AppConstants.LIST) {
                    filter = "?global=0&all=1&contact_list=" + this.contact_type;
                } else if (this.selectedGroupType === AppConstants.TAG) {
                    filter = "?global=0&all=1&tags=" + this.contact_type;
                }
            }
        } else if (this.contact_filter === "all" && this.contact_type === "all_contacts") {
            filter = "?all=1";
        }
         else {
            const selectedContacts = this.contacts.filter(c => c.isChecked);
            const ids = selectedContacts.map(c => c.id);
            filter = "?all=1&id=" + ids.toString();
        }
        this.contactRepository.exportContacts(filter, this.contact_type, this.export_type, resultName);
        this.close();
    }

    ngOnDestroy(): void {
        this.isAlive$.next(false);
        this.isAlive$.complete();
    }

    contactItemSelect(ev: any): void {
        let group = ev.source.selected.group;
        let tagLbl: string = "Tags";
        let listLbl: string = "Lists";
        this.translateService.get(["LISTS_LBL", "TAGS"]).pipe(take(1)).subscribe(res => {
            tagLbl = res.TAGS;
            listLbl = res.LISTS_LBL;
        });
        if (group === null) {
            this.selectedGroupType = null;
        } else if (group.label === listLbl) {
            this.selectedGroupType = AppConstants.LIST;
        } else if (group.label === tagLbl) {
            this.selectedGroupType = AppConstants.TAG;
        }
        this.changeDetectionRef.markForCheck();
    }

}
