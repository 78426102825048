
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, Input, Output, EventEmitter, ChangeDetectorRef, OnChanges, ViewChild, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
    selector: "vp-contact-list-selection-component",
    templateUrl: "./contact-list-selection.html",
    styleUrls: ["./contact-list-selection.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class ContactPlusListSelectionComponent implements OnInit , OnDestroy{

    contactLists: any = [];

    constructor(
        public dialogRef: MatDialogRef<ContactPlusListSelectionComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
       
    ) {
        if(this.data.data) {
            this.data.data.map(data=>{
                let dt = {id: data.id, name: data.name}
                this.contactLists.push(dt)
            })
        }
        if (this.data.selected) {
            this.data.selected.map(sel=>{
                this.contactLists.map(cl=>{
                    if(cl.id === sel.id) {
                        cl.selected = true;
                    }
                })
            })
        }
    }

    ngOnInit() {
       
    }

    ngOnDestroy() {
        this.contactLists = [];
    }

    close(): void {
        this.dialogRef.close();
    }

    selectUnselectItems(item) {
        if (item.selected) item.selected = false;
        else item['selected'] = true
    }

    onSelect() {
        let selectedList: any = [];
        this.contactLists.map(cl => {
            if (cl.selected) {
                selectedList.push(cl);
            }
        });
        setTimeout(() => {
            console.log("this.contactLists", this.contactLists)
            this.dialogRef.close({selectedList: selectedList})
        }, 500);
       
    }
}
