<!--
  ~ VNCcontact+ : A new level of contact management
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

  <div class="contact-dialog">
    <div class="vp-contact-mobile-floating-window floating_window summary-pane" *ngIf="totalSelectedContact > 0">
        <div class="left-container">
            <mat-icon class="close disable-select" style="color:white;font-size: 20px;" (click)="resetSelection()">close
            </mat-icon>
            <div class="checkbox-container disable-select">
                <mat-checkbox [(ngModel)]="selectAll" (change)="markAll(selectAll)"></mat-checkbox>
            </div>
            <span class="select_count disable-select">{{totalSelectedContact}}</span>
        </div>
        <div class="mobile_view">
            <mat-icon *ngIf="isCordova" class="disable-select" style="color:white" (click)="floatingOperation('shareContact','')">share
            </mat-icon>
            <mat-icon class="disable-select" style="color:#FFFFFF;" (click)="floatingOperation('delete','')">delete
            </mat-icon>
            <mat-icon class="disable-select" [matMenuTriggerFor]="headerMenuOperation" style="color:white">more_vert
            </mat-icon>
        </div>
    </div>

    <div class="contact__dialog-header" *ngIf="!isSearchView">
        <div class="mobile-back-button">
            <button mat-button (click)="close()">
                <mat-icon class="mdi-20px" fontSet="mdi" fontIcon="mdi-arrow-left"></mat-icon>
            </button>
        </div>
        <div class="header_lbl disable-select">
            {{ headerText }}
        </div>
        <div class="mobile-back-button" (click)="openSearchView(true)">
            <button mat-button>
                <mat-icon class="disable-select">search</mat-icon>
            </button>
        </div>
    </div>
    <div class="contact__dialog-header search-header" *ngIf="isSearchView">
        <div class="mobile-back-button">
            <button mat-button (click)="openSearchView(false);emptySearchText();">
                <mat-icon class="mdi-20px" fontSet="mdi" fontIcon="mdi-arrow-left"></mat-icon>
            </button>
        </div>
        <div class="header_lbl disable-select">
            <mat-form-field floatLabel="never">
                <input matInput #searchTextInput class="form-control" [(ngModel)]="searchText" name="label"
                    placeholder="{{ 'SEATCH_CONTACTS' | translate }}" autocomplete="nope" />
            </mat-form-field>
        </div>
        <div class="mobile-back-button">
            <button mat-button *ngIf="searchText.length > 0" (click)="emptySearchText()">
                <mat-icon class="disable-select">close</mat-icon>
            </button>
        </div>
    </div>
    <div class="contact__dialog-body" (scroll)="scrollMoreContacts($event)">
        <div class="app-disabled" *ngIf="isLoading">
            <vp-custom-spinner></vp-custom-spinner>
        </div>
        <div class="content disable-select">
            <div *ngFor="let contact of contacts | vpSearchContact : searchText" class="contact-group-item"
                (click)="openContactDetail(contact)" vpLongPress [timeout]="700"
                (onLongPress)="$event.preventDefault(); $event.stopPropagation(); selectContactItem(contact)">
                <div *ngIf="!contact.isChecked" class="user-avatar-item disable-select">
                    <vp-avatar
                        [user]="{firstLastCharacters: contact.fullName.charAt(0), color: contact.bgAvatarColor, avatarURL: contact.avatar}">
                    </vp-avatar>
                </div>
                <div *ngIf="contact.isChecked" class="checked-contact-item disable-select">
                    <mat-icon style="vertical-align: middle;" class="disable-select">done</mat-icon>
                </div>
                <div class="full-name disable-select">
                    {{contact.fullName}}
                </div>
            </div>
        </div>
    </div>

    <mat-menu #headerMenuOperation="matMenu" [class]="'contact-plus-operation-mat-menu'">
        <button mat-menu-item (click)="floatingOperation('star','')">
            <mat-icon class="mdi-18px" fontSet="mdi" fontIcon="mdi-star"></mat-icon>
            <span class="disable-select">{{'ADD_TO_FAVORITES' | translate}}</span>
        </button>
        <button mat-menu-item (click)="floatingOperation('addList','')">
            <mat-icon class="mdi-18px" fontSet="mdi" fontIcon="mdi-account-box-multiple"></mat-icon>
            <span class="disable-select">{{'ADD_LIST_LABEL' | translate}}</span>
        </button>
        <button mat-menu-item (click)="floatingOperation('addTags','')">
            <mat-icon class="mdi-18px" fontSet="mdi" fontIcon="mdi-tag"></mat-icon>
            <span class="disable-select">{{'ADD_TAGS' | translate}}</span>
        </button>
        <button mat-menu-item (click)="floatingOperation('email','')" [disabled]="!isShowSendEmailOption">
            <mat-icon class="mdi-18px" fontSet="mdi" fontIcon="mdi-email"></mat-icon>
            <span class="disable-select">{{'EMAIL_LBL' | translate}}</span>
        </button>
        <button mat-menu-item (click)="floatingOperation('event','')" [disabled]="!isShowCalendarOption">
            <mat-icon class="mdi-18px" fontSet="mdi" fontIcon="mdi-calendar-plus"></mat-icon>
            <span class="disable-select">{{'CALENDAR_EVENT' | translate}}</span>
        </button>
        <button mat-menu-item (click)="floatingOperation('audioCall','')" [disabled]="!isShowTalkOptions">
            <mat-icon class="mdi-18px" fontSet="mdi" fontIcon="mdi-phone"></mat-icon>
            <span class="disable-select">{{'VOICE_CALL' | translate}}</span>
        </button>
        <button mat-menu-item (click)="floatingOperation('videoCall','')" [disabled]="!isShowTalkOptions">
            <mat-icon class="mdi-18px" fontSet="mdi" fontIcon="mdi-video"></mat-icon>
            <span class="disable-select">{{'VIDIO_CALL' | translate}}</span>
        </button>
        <button mat-menu-item (click)="floatingOperation('chat','')" [disabled]="!isShowTalkOptions">
            <mat-icon class="mdi-18px" fontSet="mdi" fontIcon="mdi-message-text"></mat-icon>
            <span class="disable-select">{{'SEND_A_MSG_LBL' | translate}}</span>
        </button>
        <button mat-menu-item (click)="floatingOperation('broadcast','')" [disabled]="!isShowTalkOptions">
            <mat-icon class="mdi-18px" fontSet="mdi" fontIcon="mdi-bullhorn"></mat-icon>
            <span class="disable-select">{{'BROADCAST' | translate}}</span>
        </button>
        <button mat-menu-item (click)="floatingOperation('shareScreen','')" [disabled]="!isShowTalkOptions" *ngIf="!isOnMobile">
            <mat-icon class="mdi-18px" fontSet="mdi" fontIcon="mdi-monitor-share"></mat-icon>
            <span class="disable-select">{{'SHARE_SCREEN' | translate}}</span>
        </button>
        <button mat-menu-item (click)="floatingOperation('exportContact','')">
            <mat-icon class="mdi-18px" fontSet="mdi" fontIcon="mdi-export"></mat-icon>
            <span class="disable-select">{{ 'EXPORT_CONTACTS' | translate }}</span>
        </button>
        <button mat-menu-item (click)="floatingOperation('printContact','')">
            <mat-icon class="mdi-18px" fontSet="mdi" fontIcon="mdi-printer"></mat-icon>
            <span class="disable-select">{{ 'PRINT_DLG_HEADER' | translate }}</span>
        </button>
    </mat-menu>
</div>
<div class="contact-add-menu-container">
    <button mat-fab (click)="createContact()">
        <mat-icon class="mdi-24px" fontSet="mdi" fontIcon="mdi-plus"></mat-icon>
    </button>
</div>