/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, NgZone, ChangeDetectorRef, Inject } from "@angular/core";
import { Subject } from "rxjs";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Broadcaster } from "src/app/common/providers/broadcaster.service";
import { BroadcastKeys } from "src/app/common/enums/broadcast.enum";
import { takeUntil, take } from "rxjs/operators";
import { ToastService } from "src/app/common/service/tost.service";
import { SearchResponse } from "src/app/common/models/search-item";
import { Contact } from "src/app/common/models";
import { Store } from "@ngrx/store";
import { ContactRootState } from "../../store";
import { RootState, getFederatedApps, getLoggedInUserContact, getUserProfile } from "src/app/reducers";
import { environment } from "src/environments/environment";
import { ContactRepository } from "../../repository/contact.repository";
import { DomSanitizer } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { CommonUtil } from "src/app/common/utils/common.utils";

@Component({
    selector: "vp-contact-recent-actvity-dialog",
    templateUrl: "./recent-activity-dialog.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class RecentActivityDialogComponent implements OnInit, OnDestroy {

    private isAlive$ = new Subject<boolean>();
    supportedApps = ["talk", "mail", "issue"];
    searchParams: any = {
        q: "*:*",
        sort: "created_dt desc,id desc",
        start: 0,
        type_p: "",
        type_s: "",
        limit: 20
    };
    searchResponse: SearchResponse;
    fedrateApps: any;
    isShowSendEmailOption: boolean = true;
    isShowTalkOptions: boolean = true;
    isShowTaskOption: boolean = true;
    isShowTicketOption: boolean = true;
    currentUser: Contact;
    isCordova: boolean = false;
    recentActivitySelectedTab: any = 0;
    recentActivityswipeCoord: any;
    recentActivityswipeTime: number = 0;
    contact: Contact;
    emojiOnly: boolean = false;
    userJID: any = {};
    isSearchResult : boolean;
    hasMention: boolean;
    actorName: string;
    isMyMessage: boolean;
    totalParticipants: any;
    fromJid: string;
    translationKey: string;
    fullActorName: string;
    isMeeting: boolean;
    keyword: any;
    TICKET_MENTION = "ticket_mention";
    META_TASK_MENTION = "meta_task_mention";
    profileData : any;

    constructor(
        public dialogRef: MatDialogRef<RecentActivityDialogComponent>,
        private broadcaster: Broadcaster,
        private ngZone: NgZone,
        private changeDetectionRef: ChangeDetectorRef,
        private toastService: ToastService,
        private store: Store<ContactRootState | RootState>,
        private contactRepository: ContactRepository,
        private sanitizer: DomSanitizer,
        private translate: TranslateService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.contact = this.data.contact;
        this.store.select(getLoggedInUserContact).pipe(take(1)).subscribe(res => {
            if (!!res && res !== null) {
                this.currentUser = res;
            }
            this.changeDetectionRef.markForCheck();
        });
        this.isCordova = environment.isCordova;
        this.store.select(getUserProfile).pipe(takeUntil(this.isAlive$)).subscribe(res => {
            if (!!res && res !== null) {
                this.profileData = res;
            }
        });
        this.changeDetectionRef.markForCheck();
    }

    ngOnInit(): void {
        this.broadcaster.on<any>(BroadcastKeys.HIDE_RECENT_ACTIVITY_DIALOG).pipe(takeUntil(this.isAlive$)).subscribe(res => {
            this.ngZone.run(() => {
                this.close();
            });
        });
        this.store.select(getFederatedApps).pipe(takeUntil(this.isAlive$)).subscribe(apps => {
            console.log("[getFederatedApps]", apps);
            this.fedrateApps = apps;
            const isMailAppAvailable = this.fedrateApps.filter(fa => fa.name === "vncmail")[0];
            if (!!isMailAppAvailable) {
                this.isShowSendEmailOption = true;
            } else {
                this.isShowSendEmailOption = false;
            }
            const isTalkAppAvailable = this.fedrateApps.filter(fa => fa.name === "vnctalk")[0];
            if (!!isTalkAppAvailable) {
                this.isShowTalkOptions = true;
            } else {
                this.isShowTalkOptions = false;
            }
            const isTaskAppAvailable = this.fedrateApps.filter(fa => fa.name === "vnctask")[0];
            if (!!isTaskAppAvailable) {
                this.isShowTaskOption = true;
                this.checkVNCtaskRights(this.contact);
            } else {
                this.isShowTaskOption = false;
            }
            const isProjectAppAvailable = this.fedrateApps.filter(fa => fa.name === "vncproject")[0];
            if (!!isProjectAppAvailable) {
                this.isShowTicketOption = true;
                this.checkVNCprojectRights(this.contact);
            } else {
                this.isShowTicketOption = false;
            }
            this.changeDetectionRef.markForCheck();
        });
        this.callSearchActivity("*");
    }

    checkVNCtaskRights(contact: Contact) {
        if (contact && contact.username && contact.products && contact.products.length > 0) {
            const isTaskEnabled = contact.products.filter(fa => fa.name === "vnctask")[0];
            if (isTaskEnabled) {
                this.isShowTaskOption = true;
            } else {
                this.isShowTaskOption = false;
            }
        } else {
            this.isShowTaskOption = false;
        }
    }

    checkVNCprojectRights(contact: Contact) {
        if (contact && contact.username && contact.products && contact.products.length > 0) {
            const isProjectEnabled = contact.products.filter(fa => fa.name === "vncproject")[0];
            if (isProjectEnabled) {
                this.isShowTicketOption = true;
            } else {
                this.isShowTicketOption = false;
            }
        } else {
            this.isShowTicketOption = false;
        }
    }

    close(): void {
        this.dialogRef.close();
    }

    ngOnDestroy(): void {
        this.isAlive$.next(false);
        this.isAlive$.complete();
    }

    callSearchActivity(type_s: string, type_p?: string): void {
        if (!!this.searchResponse) {
            this.searchResponse.docs = [];
            this.changeDetectionRef.markForCheck();
        }
        if (this.contact.emails && this.contact.emails.length > 0) {
            this.searchParams.from_s = (this.contact.jid) ? this.contact.jid : this.contact.emails[0].email;
            if (type_p) {
                this.searchParams.type_p = type_p;
            }
            if (type_s) {
                this.searchParams.type_s = type_s;
            }
            this.contactRepository.searchDocs(this.searchParams).pipe(take(1)).subscribe(docs => {
                console.log("Recent activity for selected contact", docs);
                this.searchResponse = docs;
                this.contactRepository.getSelectedContactName().subscribe((contacts: Contact[]) => {
                    this.searchResponse.docs.map(doc => {
                        this.processMessageBody(doc).then((res: any) => {
                            doc.contentTxt = this.getInnerText(res.changingThisBreaksApplicationSecurity);
                        });
                    });
                });
                console.log("[searchResponse]", this.searchResponse);
                this.changeDetectionRef.markForCheck();
            });
        }
    }

    onSelectedTabChangeActivity(ev: any) {
        if (ev.index === 1) {
            this.callSearchActivity("talk", "chat");
        } else if (ev.index === 2) {
            this.callSearchActivity("talk", "call");
        } else if (ev.index === 3) {
            this.callSearchActivity("mail");
        } else if (ev.index === 4) {
            this.callSearchActivity("issue", "task");
        } else if (ev.index === 5) {
            this.callSearchActivity("issue", "ticket");
        } else if (ev.index === 6) {
            this.callSearchActivity("issue", "incident");
        } else if (ev.index === 0) {
            this.callSearchActivity("*");
        }
    }

    getBgAvatarColor(jid) {
        if (this.currentUser && (this.currentUser.jid === jid || this.currentUser.emails[0] === jid)) {
            return this.currentUser.bgAvatarColor;
        } else {
            return this.contact.bgAvatarColor;
        }
    }

    getAvatarUrl(jid) {
        if (this.currentUser && (this.currentUser.jid === jid || this.currentUser.emails[0] === jid)) {
            return this.currentUser.avatar;
        } else {
            return this.contact.avatar;
        }
    }

    openRecentActivity(item) {
        if (item.type === "talk") {
            if (item.chatType === "chat") {
                this.contactRepository.jumpToChat(this.contact, item.talkId, item.createdDt);
            } else {
                this.underDevelopment();
            }
        } else if (item.type === "mail") {
            if (this.contactRepository.restrictOpenMail(item)) {
                this.contactRepository.openMail(item.id);
            }
        } else if (item.type === "issue") {
            if ( item.issue_type === "task") {
                this.contactRepository.openTask(item.id);
            }
            if ( item.issue_type === "ticket" || item.issue_type === "incident") {
                this.contactRepository.openTicket(item.id);
            }
        } else {
            this.underDevelopment();
        }
    }

    underDevelopment() {
        this.toastService.show("COMING_SOON");
    }

    recentActivityswipe (e: TouchEvent, when: string): void {
        const coord: [number, number] = [
            e.changedTouches[0].clientX,
            e.changedTouches[0].clientY
        ];
        const time = new Date().getTime();
        if (when === "start") {
            this.recentActivityswipeCoord = coord;
            this.recentActivityswipeTime = time;
        } else if (when === "end") {
            const direction = [coord[0] - this.recentActivityswipeCoord[0], coord[1] - this.recentActivityswipeCoord[1]];
            const duration = time - this.recentActivityswipeTime;
            if (duration < 1000 && Math.abs(direction[0]) > 30 && Math.abs(direction[0]) > Math.abs(direction[1] * 3)) {
                const swipe = direction[0] < 0 ? "next" : "previous";
                if (swipe === "next") {
                    const isFirst = this.recentActivitySelectedTab === 0;
                    if (this.recentActivitySelectedTab <= 5) {
                        this.recentActivitySelectedTab = isFirst ? 1 : this.recentActivitySelectedTab + 1;
                    }
                } else if (swipe === "previous") {
                    const isLast = this.recentActivitySelectedTab === 6;
                    if (this.recentActivitySelectedTab >= 1) {
                        this.recentActivitySelectedTab = this.recentActivitySelectedTab - 1;
                    }
                }
            }
        }
        this.changeDetectionRef.markForCheck();
    }

    getInnerText(htmlString) {
        let element = document.createElement("div");
        element.innerHTML = htmlString;
        return this.extractTextContent(element);
    }

    extractTextContent(element) {
        let text = "";
        for (let node of element.childNodes) {
            if (node.nodeType === Node.TEXT_NODE) {
                text += node.textContent.trim() + " ";
            } else if (node.nodeType === Node.ELEMENT_NODE) {
                text += this.extractTextContent(node);
            }
        }
        return text.trim();
    }

    private processMessageBody(message: any, selectedContact: string = "", skipEmoji?: boolean, processOnly?: boolean) {
        let messageBody = message?.contentTxt || "";
        try {
            if (message.htmlBody) {
                messageBody = message.htmlBody;
            } else if (message.html && message.html.body) {
                messageBody = message.html.body;
            }
            if (!!messageBody) {
                messageBody = messageBody.trim();
                if (/^:[a-zA-Z0-9-_+]+:$/g.test(messageBody)) {
                    this.emojiOnly = true;
                }
            }

        } catch (error) {
            // eslint-disable-next-line no-console
            console.warn("[processMessageBody]", error);
        }

        return new Promise(async (resolve) => {
            const references = message.references;
            let isTicketMention = false;

            if (messageBody.startsWith("#quickreply_")) {
                const additionalActionsTranslationKeys = ["QUICK_REPLY_CANT_TALK_WHATS_UP", "QUICK_REPLY_CALL_YOU_RIGHT_BACK", "QUICK_REPLY_CALL_YOU_LATER", "QUICK_REPLY_CANT_TALK_CALL_ME_LATER"];
                let additionActionTranslations = {};
                this.translate.get(additionalActionsTranslationKeys).pipe(take(1)).subscribe(v => {
                    additionActionTranslations = v;
                });
                try {
                    const translateKey = messageBody.split("quickreply_")[1].trim();
                    if (additionalActionsTranslationKeys.indexOf(translateKey) > -1) {
                        messageBody = additionActionTranslations[translateKey];
                        message.cachedContent = additionActionTranslations[translateKey];
                    }
                } catch (error) {

                }
            }

            if (!message.forwardMessage) {
                let translations: any = {};
                this.translate.get(["TICKET_MENTION_RECEIVER_MESSAGE", "TICKET_MENTION_SENDER_MESSAGE", "META_TASK_MENTION_RECEIVER_MESSAGE", "META_TASK_MENTION_SENDER_MESSAGE"]).pipe(take(1)).subscribe(v => {
                    translations = v;
                });
                isTicketMention = CommonUtil.parseRedmineMentions(this.TICKET_MENTION, messageBody).length > 0 || CommonUtil.parseRedmineMentions(this.META_TASK_MENTION, messageBody).length > 0;
                try {
                    if (CommonUtil.parseRedmineMentions(this.TICKET_MENTION, messageBody).length > 0) {
                        const textToReplace = CommonUtil.parseRedmineMentions(this.TICKET_MENTION, messageBody)[0];
                        if(message.from.includes(this.profileData.login) ) {
                            let mentionText = translations.TICKET_MENTION_SENDER_MESSAGE.replace("{{ receiver_name }}", this.contact.fullName)
                            messageBody = messageBody.replace(textToReplace, mentionText).replace(/&lt;/g, "<").replace(/&gt;/g, ">");
                            isTicketMention = true;
                        } else {
                            let userjid = textToReplace.split("#")[1];
                            const mentionFrom = await this.contactRepository.getAllContactFromDBById(message.from);
                            let mentionText = translations.TICKET_MENTION_RECEIVER_MESSAGE.replace("{{ sender_name }}", mentionFrom)
                            if (this.currentUser.jid !== userjid) {
                                mentionText = translations.TICKET_MENTION_SENDER_MESSAGE.replace("{{ receiver_name }}", this.contact.fullName)
                            }
                            isTicketMention = true;
                            messageBody = messageBody.replace(textToReplace, mentionText).replace(/&lt;/g, "<").replace(/&gt;/g, ">");
                        }
                        

                    } else if (CommonUtil.parseRedmineMentions(this.META_TASK_MENTION, messageBody).length > 0) {

                        const textToReplace = CommonUtil.parseRedmineMentions(this.META_TASK_MENTION, messageBody)[0];
                        let userjid = textToReplace.split("#")[1];
                        const mentionFrom = await this.contactRepository.getAllContactFromDBById(message.from);
                        let mentionText = translations.TICKET_MENTION_RECEIVER_MESSAGE.replace("{{ sender_name }}", mentionFrom)
                        if (this.currentUser.jid !== userjid) {
                            mentionText = translations.META_TASK_MENTION_SENDER_MESSAGE.replace("{{ receiver_name }}", this.contact.fullName)
                        }
                        isTicketMention = true;
                        messageBody = messageBody.replace(textToReplace, mentionText).replace(/&lt;/g, "<").replace(/&gt;/g, ">");
                    }
                } catch (error) {

                }
            }

            if (!!message.cachedContent && !this.isSearchResult && !skipEmoji && !isTicketMention) {
                messageBody = message.cachedContent;
            } else {
                messageBody = CommonUtil.generateCachedBody(messageBody, this.isSearchResult ? this.keyword : null, skipEmoji);
                if (!this.isSearchResult && !skipEmoji) {
                    const newMsg = { ...message, cachedContent: messageBody };
                }
            }


            if (message.group_action && (message.group_action === "ADD_PARTICIPANTS"
                || message.group_action.type === "ADD_PARTICIPANTS")) {
                if (message.group_action.data) {
                    const participants = message.group_action.data.split(",");
                    messageBody = messageBody.split("<br>")[1];
                    if (!messageBody) { // e.g. when add a new user to omemo chat
                        messageBody = "";
                        participants.forEach((pJid, index) => {
                            const pDisplayName = pJid.split("@")[0].replace(".", " ");
                            messageBody += pDisplayName;
                            if (index !== participants.length - 1) {
                                messageBody += ", ";
                            }
                        });
                    }
                    this.totalParticipants = participants.length;
                } else {
                    const firstLine = messageBody.split("<br>")[0];
                    messageBody = messageBody.split("<br>")[1];
                    if (!messageBody) { // e.g. when add a new user to omemo chat
                        this.totalParticipants = 1;
                        messageBody = "";
                    } else {
                        this.totalParticipants = parseInt(firstLine.replace(/\D+/g, ""));
                    }
                }
                this.actorName = "this.contactRepo.getFullName(this.fromJid)";
                this.translationKey = this.totalParticipants > 1 ? "USER_ADD_N_PARTICIPANTS" : "USER_ADD_N_PARTICIPANT";
            } else if (message.group_action && (message.group_action === "UPDATE_AVATAR"
                || message.group_action.type === "UPDATE_AVATAR")) {
                this.fullActorName = messageBody.replace("has updated the group photo", "").replace("hat das Foto der Gruppe aktualisiert", "");
                this.translationKey = "HAS_UPDATED_GROUP_PHOTO";
            } else if (message.group_action && (message.group_action === "UPDATE_ENCRYPTION"
                || message.group_action.type === "UPDATE_ENCRYPTION" || (!!message.group_action.type.type && message.group_action.type.type === "UPDATE_ENCRYPTION"))) {
                this.translationKey = "ENABLED_E2E_ENCRYPTION";
                if (messageBody.indexOf("disabled E2E encryption for the chat") !== -1) {
                    this.translationKey = "DISABLED_E2E_ENCRYPTION";
                }
                this.fullActorName = messageBody.replace("enabled E2E encryption for the chat", "").replace("disabled E2E encryption for the chat", "");
            }
            else if (message.group_action && (message.group_action === "created"
                || message.group_action.type === "created")) {
                // this.logger.info("groupCreatedCheck: ", message);
                if (!!message.convTarget) {
                    if (message.convTarget.indexOf("talk_meeting@conference") > -1) {
                        this.isMeeting = true;
                    }
                }
                // this.translationParam = { groupName: messageBody.replace(/Group\s"/, "").replace(/"\sCreated/, "")};
            } else if (!!message.group_action && typeof message.group_action?.type === "string" && message.group_action?.type?.indexOf("MISSED_CALL") !== -1) {
                try {
                    const data = JSON.parse(message.group_action?.type);
                    let missCallJids = data.jids;
                } catch (ex) {
                    // this.logger.sentryErrorLog("parse group_action error", ex);
                }
            } else if (message.group_action && message.group_action.type === "JOINED_PARTICIPANT") {
                const joinee = message.body.split(" ")[0];
                this.fullActorName = "this.contactRepo.getFullName(joinee)";
                if (joinee === this.currentUser.jid) {
                    this.translationKey = "YOU_JOINED_THE_GROUPCHAT";
                } else {
                    this.translationKey = "JOINED_THE_GROUPCHAT";
                }
            }

            const generateSecretKey = "_._" + message.to.bare;
            const isMentionedMessageFromTopic = message?.body?.startsWith(generateSecretKey);
            if (messageBody && messageBody.includes("TOPIC_MENTION_MESSAGE_RECEIVER") && isMentionedMessageFromTopic) {
                if (this.isMyMessage) {
                    const receiverName = "this.getFullName.transform(message.to.bare)";
                    const translatedMessage = await this.translate.get("TOPIC_MENTION_MESSAGE_SENDER").pipe(take(1)).toPromise();
                    const senderSideMessage = translatedMessage.replace("{{ USERNAME }}", receiverName);
                    messageBody = messageBody.replace("TOPIC_MENTION_MESSAGE_RECEIVER", senderSideMessage);
                } else {
                    const translatedMessage = await this.translate.get("TOPIC_MENTION_MESSAGE_RECEIVER").pipe(take(1)).toPromise();
                    messageBody = messageBody.replace("TOPIC_MENTION_MESSAGE_RECEIVER", translatedMessage);
                }
                messageBody = messageBody.replace(generateSecretKey, "");
            }

            if (message.forwardMessage) {
                let translations: any = {};
                this.translate.get(["TICKET_MENTION_RECEIVER_MESSAGE", "TICKET_MENTION_SENDER_MESSAGE", "META_TASK_MENTION_RECEIVER_MESSAGE", "META_TASK_MENTION_SENDER_MESSAGE"]).pipe(take(1)).subscribe(v => {
                    translations = v;
                });
                try {
                    if (CommonUtil.parseRedmineMentions(this.TICKET_MENTION, messageBody).length > 0) {
                        const textToReplace = CommonUtil.parseRedmineMentions(this.TICKET_MENTION, messageBody)[0];
                        let userjid = textToReplace.split("#")[1];
                        // "this.contactRepo.getFullName(message.forwardMessage.from)"
                        console.log("inside The data 3")
                        const mentionFrom = await this.contactRepository.getAllContactFromDBById(message.from);
                        let mentionText = translations.TICKET_MENTION_RECEIVER_MESSAGE.replace("{{ sender_name }}", mentionFrom)
                        if (this.currentUser.jid !== userjid) {
                            mentionText = translations.TICKET_MENTION_SENDER_MESSAGE.replace("{{ receiver_name }}", this.contact.fullName)
                        }
                        messageBody = messageBody.replace(textToReplace, mentionText);
                    } else if (CommonUtil.parseRedmineMentions(this.META_TASK_MENTION, messageBody).length > 0) {
                        const textToReplace = CommonUtil.parseRedmineMentions(this.META_TASK_MENTION, messageBody)[0];
                        let userjid = textToReplace.split("#")[1];
                        const mentionFrom = await this.contactRepository.getAllContactFromDBById(message.from);
                        let mentionText = translations.TICKET_MENTION_RECEIVER_MESSAGE.replace("{{ sender_name }}", mentionFrom)
                        if (this.currentUser.jid !== userjid) {
                            mentionText = translations.META_TASK_MENTION_SENDER_MESSAGE.replace("{{ receiver_name }}", this.contact.fullName)
                        }
                        messageBody = messageBody.replace(textToReplace, mentionText);
                    }
                } catch (error) {

                }
                if (!processOnly) {
                    messageBody = this.sanitizer.bypassSecurityTrustHtml(messageBody.replace(/<a href=/g, "<a target=\"_blank\" class=\"open-new-window\" href=")
                        .replace(/<a class="([a-z\s0-9]*)"\shref=/g, "<a target=\"_blank\" class=\"open-new-window\" href="));
                }
            }

            if (messageBody.includes("http://") || messageBody.includes("https://")) {
            }
            if (!skipEmoji) {
                messageBody = this.sanitizer.bypassSecurityTrustHtml(messageBody.replace(/<a href=/g, "<a target=\"_blank\" class=\"open-new-window\" href=")
                    .replace(/<a class="([a-z\s0-9]*)"\shref=/g, "<a target=\"_blank\" class=\"open-new-window\" href="));
                this.changeDetectionRef.markForCheck();
            }
            resolve(messageBody);
        });
    }

}
