
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, OnInit, NgZone, OnDestroy } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { BroadcastKeys } from "src/app/common/enums/broadcast.enum";
import { takeUntil } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { Broadcaster } from "src/app/common/providers/broadcaster.service";
import { ConfigService } from "src/app/common/providers/config.service";
import { CommonUtil } from "src/app/common/utils/common.utils";
import { Subject } from "rxjs";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

@Component({
  selector: "vp-about-dialog",
  templateUrl: "./about-dialog.component.html"
})
export class AboutDialogComponent implements OnDestroy, OnInit {

  app_version: string = "1.5.4.350";
  aboutLogo: SafeResourceUrl;
  aboutText: string;
  changeLog: string;
  backgroundImage = "url(" + CommonUtil.getFullUrl("/assets/img/login-background.png") + ")";
  private isAlive$ = new Subject<boolean>();

  constructor(
    public dialogRef: MatDialogRef<AboutDialogComponent>,
    private broadcaster: Broadcaster,
    private domSanitizer: DomSanitizer,
    private ngZone: NgZone,
    private configService: ConfigService
  ) {
    this.broadcaster.on<any>(BroadcastKeys.HIDE_VERSION_DIALOG).pipe(takeUntil(this.isAlive$)).subscribe(res => {
      this.ngZone.run(() => {
        this.close();
      });
    });
    if (environment.isCordova) {
      document.addEventListener("deviceready", this.deviceReady.bind(this), false);
    }
    // this.aboutLogo = CommonUtil.getFullUrl("/assets/images/vnc-contacts-plus.png");
    this.aboutLogo = this.domSanitizer.bypassSecurityTrustResourceUrl(CommonUtil.getFullUrl("/assets/images/vnc-contacts-plus.png"));
    console.log("aboutLogo: ", this.aboutLogo);
    if (this.configService.URLS.changeLog) {
      this.changeLog = this.configService.URLS.changeLog;
    } else {
      this.changeLog = "https://vnclagoon.com/release-notes/";
    }
  }

  ngOnInit(): void {

  }

  deviceReady() {
    try {
      cordova.getAppVersion.getVersionNumber().then(versionNumber => {
        console.log("AboutDialogComponent versionNumber", versionNumber);
        if (CommonUtil.isOnAndroid()) {
          // for Android we use ${VERSION}.${env.BUILD_NUMBER} as a version
          // so do not need to pass a build number since version contains both.
          this.app_version = versionNumber;
          console.log("AboutDialogComponent Android this.app_version", this.app_version);
        } else {
          cordova.getAppVersion.getVersionCode().then(buildNumber => {
            console.log("AboutDialogComponent buildNumber", buildNumber);
            this.app_version = versionNumber + "." + buildNumber;
            console.log("AboutDialogComponent iOS this.app_version", this.app_version);
          });
        }
      });
    } catch (e) {
      console.log("[getVersionNumber] err", e);
    }
  }

  close() {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.isAlive$.next(false);
    this.isAlive$.complete();
  }
}
