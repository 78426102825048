
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { NgModule, Injectable } from "@angular/core";
import { RouterModule, Routes, CanLoad, Route, PreloadAllModules } from "@angular/router";
import { AvailableRoutes } from "./common/providers/route.guards";
import { ConfigService } from "./common/providers/config.service";
import { AuthService } from "./common/providers/auth.service";
import { Observable } from "rxjs";
import { environment } from "../environments/environment";
import { map } from "rxjs/operators";
import { PageNotFoundComponent } from "./shared/components/page-not-found/page-not-found.component";

@Injectable()
export class AuthGuard implements CanLoad {
  isCordovaOrElectron = environment.isCordova || environment.isElectron;
  constructor(
    private configService: ConfigService,
    private authService: AuthService
  ) {
  }

  canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
    if (this.isCordovaOrElectron && !this.configService.selectedServer) {
      return true;
    }

    return this.authService.getProfile().
      pipe(map(profile => {
        if (!profile) {
          if (this.isCordovaOrElectron) {
            return true;
          } else {
            window.location.href = this.configService.API_URL + "/api/login";
          }
        }
        return !!profile;
      }));
  }
}

const routes: Routes = [
  {
    path: "",
    redirectTo: "contactplus",
    pathMatch: "full"
  },
  {
    path: "contactplus",
    canLoad: [AuthGuard],
    // canActivate: [AvailableRoutes],
    data: { sidebar: true, appName: "VNCcontactsplus", path: "/contactplus" },
    loadChildren: () => import('./contacts/contacts.module').then(m => m.ContactsModule)
  },
  {
    path: "**",
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: false,
    enableTracing: false,
    preloadingStrategy: PreloadAllModules
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
