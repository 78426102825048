import { openDB, deleteDB } from "idb/with-async-ittr.js";
import { Observable, Subject } from "rxjs";
import { take } from "rxjs/operators";
import { DatabaseInterface } from "./database.interface";

const CONTACTS_DB_STORE = "ContactsStore";
const AVATAR_DB_STORE = "AvatarsStore";
const CONTACT_LIST_DB_STORE = "ContactListStore";
const CONTACT_GROUP_DB_STORE = "ContactGroupStore";
const PENDING_OP_STORE = "PendingOperationStore";
const INDEX_IS_GLOBAL = "by-is-global"
const INDEX_BY_JID = "by-jid";
const INDEX_BY_CONTACT_LISTS = "by-contact-lists";
const INDEX_BY_CONTACT_ID = "by-contact-id";
const INDEX_BY_NAME = "by-name";
const INDEX_BY_DELETED_AT = "by-deleted-at";
const INDEX_BY_GLOBAL_DELETED = "by-global-deleted";
const INDEX_BY_PENDING = "by-pending";
const INDEX_BY_TYPE = "by-type";
export class IndexedDBService implements DatabaseInterface {
    dbName = "ContactsPlusDatabase";
    dbVersion = 4;
    // A list of all versions:E
    // 2: added avatarstore

    constructor() {
      console.log("[IndexedDBService][constructor]");
    }

    initDatabase() {
        this.idbContext().then(() => {
            console.log("[IndexedDBService][initDatabase]");
        });
    }

    deleteContact(contact: any): Observable<any> {
        const response = new Subject<any>();
        this.idbContext().then(db => {
          const tx = db.transaction(CONTACTS_DB_STORE, "readwrite");
          tx.store.delete(contact.id);
          tx.done.then(() => {
            response.next(true);
          }).catch(error => {
            response.error(error);
          });
        });
        return response.asObservable().pipe(take(1));
    }

    deleteContacts(ids: any[]): Observable<any> {
        const response = new Subject<any>();
        this.idbContext().then(db => {
          const tx = db.transaction(CONTACTS_DB_STORE, "readwrite");
          ids.forEach(id => {
            tx.store.delete(id);
          });
          tx.done.then(() => {
            response.next(true);
          }).catch(error => {
            response.error(error);
          });
        });
        return response.asObservable().pipe(take(1));
    }

    getContactId(id: string): Observable<any> {
        const response = new Subject<any>();
        this.idbContext().then(db => {
          const tx = db.transaction(CONTACTS_DB_STORE);
          const store = tx.objectStore(CONTACTS_DB_STORE);
          store.get(IDBKeyRange.only(id)).then(msg => {
            response.next(msg);
          }).catch(error => {
            response.error(error);
          });
        });
        return response.asObservable().pipe(take(1));
    }

    createOrUpdateContacts(contacts: any[]): Observable<any> {
        console.log("[IndexedDBService][createOrUpdateContacts]", contacts);
        const response = new Subject<any>();
        this.idbContext().then(db => {
          const tx = db.transaction(CONTACTS_DB_STORE, "readwrite");
          contacts.forEach(contact => {
            const contact_list_ids = (!!contact.contact_lists && contact.contact_lists.length > 0) ? contact.contact_lists.map(l => l.id) : [];
            // const lists = contact.contact_lists;
            const contactToStore = { ...contact };
            const global = contact.is_global;
            // console.log("createOrUpdateContact-type ", typeof global, contact, JSON.stringify(contact));
            // console.log("createOrUpdateContact-list ", lists, JSON.stringify(lists), contact_list_ids, JSON.stringify(contactToStore));
            // const isGlobal = (!!contact.is_global && contact.is_global === true || contact.is_global === "true") ? "true" : "false";
            contactToStore.contact_list_ids = contact_list_ids;
            console.log("createOrUpdateContact contactToStore: ", contactToStore);
            tx.store.put(contactToStore);
          });

          tx.done.then(() => {
            response.next(true);
          }).catch(error => {
            response.error(error);
          });
        });
        return response.asObservable().pipe(take(1));
    }

    fetchContacts(global?: boolean, deleted?: boolean): Observable<any> {
        const response = new Subject<any>();
        this.idbContext().then(db => {
          const param = !!global ? "true" : "false";
          db.getAllFromIndex(CONTACTS_DB_STORE, INDEX_IS_GLOBAL, param).then(result => {
            if (!!deleted) {
              const deletedContacts = result.filter(v => !!v.deleted_at);
              response.next(deletedContacts);
            } else {
              const contacts = result.filter(v => !v.deleted_at);
              console.log("[idb fetchContacts getContacts : ", contacts);
              response.next(contacts);
            }
          }).catch(error => {
            response.error(error);
          });
        });

        return response.asObservable().pipe(take(1));
    }

    getContactCountInDatabase(): Observable<any> {
      const response = new Subject<any>();

      this.idbContext().then(async db => {
        const tx = db.transaction(CONTACTS_DB_STORE, "readonly");
        const store = tx.objectStore(CONTACTS_DB_STORE);
        const index = store.index(INDEX_IS_GLOBAL);
        const index2 = store.index(INDEX_BY_DELETED_AT);
        const count = await index.count("false");
        const deleted = await index2.count();
        const global_contacts_count = await index.count("true");
        console.log("indexeddbservice getContactsCountInDatabase: ", count, deleted, global_contacts_count);

        tx.done.then(() => {
          const res = {
            count: count - deleted,
            global_contacts_count: global_contacts_count
          };
          response.next(res);
        }).catch(error => {

          response.error(error);
        });


      });

      return response.asObservable().pipe(take(1));
    }

  storeAvatar(avatarB64Url: string, avatarUrl: string, email: string): Observable<any> {
      const response = new Subject<any>();
      this.idbContext().then(db => {
        const tx = db.transaction(AVATAR_DB_STORE, "readwrite");
        const ts = Date.now();
        if (!!email && email !== "") {
          tx.store.put({ id: email, data: avatarB64Url, origUrl: avatarUrl, ts: ts});
        }
        tx.done.then(() => {
          response.next(true);
        }).catch(error => {
          console.error("[IndexedDBService][storeAvatar]", error);
          response.error(error);
        });
      });

      return response.asObservable().pipe(take(1));
    }

    deleteAvatar(email: string): Observable<any> {
      const response = new Subject<any>();

      this.idbContext().then(db => {
        const tx = db.transaction(AVATAR_DB_STORE, "readwrite");
        tx.store.delete(email);
        tx.done.then(() => {
          response.next(true);
        }).catch(error => {
          console.error("[IndexedDBService][deleteAvatar]", error);
          response.error(error);
        });
      });

      return response.asObservable().pipe(take(1));
    }

    getAvatarByEmail(email: string): Observable<any> {
      const response = new Subject<any>();
      this.idbContext().then(db => {
        const tx = db.transaction(AVATAR_DB_STORE);
        const store = tx.objectStore(AVATAR_DB_STORE);
        if (!!email && email !== ""){
          store.get(IDBKeyRange.only(email)).then(data => {
            if (navigator.onLine) {
              if (!!data && !!data.ts && ((Date.now() - data.ts) > 86400000)) {
                response.next(null);
              } else {
                response.next(data);
              }
            } else {
              response.next(data);
            }
          }).catch(error => {
            console.error("[IndexedDBService][getAvatarByBare] error", error);
            response.next(null);
          });
        } else {
          setTimeout(() => {
            response.next(null);
          }, 1);
        }
      });
      return response.asObservable().pipe(take(1));
    }

    fetchAllAvatarFromDatabase(): Observable<any[]> {
      const response = new Subject<any>();
      this.idbContext().then(db => {
        const tx = db.transaction(AVATAR_DB_STORE);
        tx.store.getAll().then(avatars => {
          console.log("[IndexedDBService][fetchAllAvatarFromDatabase] Ok", avatars);
          response.next(avatars);
        }).catch(error => {
          console.error("[IndexedDBService][fetchAllAvatarFromDatabase] error", error);
          response.next([]);
        });
      });
      return response.asObservable().pipe(take(1));
    }


    createOrUpdateContactFolders(folders: any[]): Observable<any> {
      console.log("[IndexedDBService][createOrUpdateContactFolders]", folders);
      const response = new Subject<any>();

      this.idbContext().then(db => {
        const tx = db.transaction(CONTACT_LIST_DB_STORE, "readwrite");
        folders.forEach(folder => {
          const folderToStore = { ...folder };
          console.log("[IndexedDBService][createOrUpdateContactFolders] each", folder, folderToStore);
          tx.store.put(folderToStore);
        });

        tx.done.then(() => {
          response.next(true);
        }).catch(error => {
          response.error(error);
        });
      });
      return response.asObservable().pipe(take(1));
    }

    getContactLists(): Observable<any[]> {
      const response = new Subject<any>();
      this.idbContext().then(db => {
        const tx = db.transaction(CONTACT_LIST_DB_STORE);
        tx.store.getAll().then(lists => {
          console.log("[IndexedDBService][getContactLists] Ok", lists);
          response.next(lists);
        }).catch(error => {
          console.error("[IndexedDBService][getContactLists] error", error);
          response.next([]);
        });
      });
      return response.asObservable().pipe(take(1));
    }

    getContactListById(id): Observable<any[]> {
      const response = new Subject<any>();
      this.idbContext().then(db => {
        const tx = db.transaction(CONTACT_LIST_DB_STORE);
          tx.store.get(IDBKeyRange.only(id)).then(lists => {
            console.log("[IndexedDBService][getContactListById] Ok", lists);
          response.next(lists);
        }).catch(error => {
          console.error("[IndexedDBService][getContactListById] error", error);
          response.next([]);
        });
      });
      return response.asObservable().pipe(take(1));
    }


    getContactsByList(id): Observable<any[]> {
      const response = new Subject<any>();

      this.idbContext().then(async db => {
        const tx = db.transaction(CONTACTS_DB_STORE, "readonly");
        const store = tx.objectStore(CONTACTS_DB_STORE);
        const index = store.index(INDEX_BY_CONTACT_LISTS);

        const contacts = await index.getAll(+id);
        const contacts2return = contacts.filter(c => !c.deleted_at);

        console.log("indexeddb.service getContactFromList: ", id, contacts2return);
        response.next(contacts2return);
      });

      return response.asObservable().pipe(take(1));
    }

    createOrUpdateContactGroup(groups: any[]): Observable<any> {
      console.log("[IndexedDBService][createOrUpdateContactGroup]", groups);
      const response = new Subject<any>();

      this.idbContext().then(db => {
        const tx = db.transaction(CONTACT_GROUP_DB_STORE, "readwrite");
        groups.forEach(group => {
          const groupToStore = { ...group };
          console.log("[IndexedDBService][createOrUpdateContactGroup] each", group, groupToStore);
          tx.store.put(groupToStore);
        });

        tx.done.then(() => {
          response.next(true);
        }).catch(error => {
          response.error(error);
        });
      });
      return response.asObservable().pipe(take(1));
    }

  getAllContactGroup(): Observable<any[]> {
    const response = new Subject<any>();

    this.idbContext().then(async db => {
      const tx = db.transaction(CONTACT_GROUP_DB_STORE, "readonly");
      const store = tx.objectStore(CONTACT_GROUP_DB_STORE);

      const groups = await store.getAll();
      console.log("indexeddb.service getAllContactGroup: ", groups);
      response.next(groups);
    });

    return response.asObservable().pipe(take(1));
  }

  getContactGroup(id: string): Observable<any> {
    const response = new Subject<any>();
    this.idbContext().then(db => {
      const tx = db.transaction(CONTACT_GROUP_DB_STORE);
      const store = tx.objectStore(CONTACT_GROUP_DB_STORE);
      store.get(IDBKeyRange.only(id)).then(msg => {
        response.next(msg);
      }).catch(error => {
        response.error(error);
      });
    });
    return response.asObservable().pipe(take(1));
  }


  getAllContactIds(): Observable<any[]> {
    const response = new Subject<any>();

    this.idbContext().then(async db => {
      const tx = db.transaction(CONTACTS_DB_STORE, "readonly");
      const store = tx.objectStore(CONTACTS_DB_STORE);

      const allIds = await store.getAllKeys();
      console.log("indexeddb.service getAllContactIds: ", allIds);
      response.next(allIds);
    });

    return response.asObservable().pipe(take(1));
  }

  addPendingOp(op: any, type: string): Observable<any> {
    const response = new Subject<any>();
    this.idbContext().then(async db => {
      const tx = db.transaction(PENDING_OP_STORE, "readwrite");
      const id = (!!op && !!op.contact && !!op.contact.contact && !!op.contact.contact.id) ? op.contact.contact.id : op.id;

      const resid = await tx.store.put( {operation: op, type: type, id: id});
      const result = { id: resid, operation: op };

      tx.done.then(() => {
        response.next(result);
      }).catch(error => {
        console.error("[IndexedDBService][addPendingOp] ", error);
        response.error(error);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  addPendingOps(ops: any[], type: string): Observable<any> {
    const response = new Subject<any>();
    this.idbContext().then(async db => {
      const tx = db.transaction(PENDING_OP_STORE, "readwrite");
      const results = [];

      ops.forEach(async op => {
        const id = (!!op && !!op.contact && !!op.contact.contact && !!op.contact.contact.id) ? op.contact.contact.id : op.id;
        const resid = await tx.store.put({ operation: op, type: type, id: id });
        results.push({ id: resid, operation: op });
      });

      tx.done.then(() => {
        response.next(results);
      }).catch(error => {
        console.error("[IndexedDBService][addPendingOps] ", error);
        response.error(error);
      });
    });

    return response.asObservable().pipe(take(1));
  }


  getAllPendingOperations(): Observable<any[]> {
    const response = new Subject<any>();

    this.idbContext().then(async db => {
      const tx = db.transaction(PENDING_OP_STORE, "readonly");
      const store = tx.objectStore(PENDING_OP_STORE);

      const pendingOps = await store.getAll();
      console.log("indexeddb.service getAllPendingOps: ", pendingOps);
      response.next(pendingOps);
    });

    return response.asObservable().pipe(take(1));
  }

  getPendingOpsByContact(id): Observable<any[]> {
    const response = new Subject<any>();

    this.idbContext().then(async db => {
      const tx = db.transaction(PENDING_OP_STORE, "readonly");
      const store = tx.objectStore(PENDING_OP_STORE);
      const index = tx.store.index(INDEX_BY_CONTACT_ID);

      const pendingOps = await store.getAll(id);
      console.log("indexeddb.service getPendingOpsByContact: ", id, pendingOps);
      response.next(pendingOps);
    });

    return response.asObservable().pipe(take(1));
  }

  deletePendingOpById(id): Observable<any> {
    const response = new Subject<any>();
    this.idbContext().then(db => {
      const tx = db.transaction(PENDING_OP_STORE, "readwrite");
      console.log("indexeddb.service deletePendingOp: ", id);
      tx.store.delete(id);
      tx.done.then(() => {
        response.next(true);
      }).catch(error => {
        response.error(error);
      });
    });
    return response.asObservable().pipe(take(1));
  }


    private idbContext() {
      return openDB(this.dbName, this.dbVersion, {
        upgrade(db, oldVersion, newVersion, transaction) {
          console.log("[IndexedDBService][idbContext][upgrade]", oldVersion, newVersion);

          if (oldVersion === 0) {
            console.log("[IndexedDBService][idbContext][upgrade] default");

            // Users store
            const contactsStore = db.createObjectStore(CONTACTS_DB_STORE, {
              // The 'id' property of the object will be the key.
              keyPath: "id",
              autoIncrement: false
            });
            contactsStore.createIndex(INDEX_IS_GLOBAL, "is_global");
          }
          if (newVersion > 1) {
            if (!db.objectStoreNames.contains(CONTACT_LIST_DB_STORE)) {
              db.createObjectStore(CONTACT_LIST_DB_STORE, {
                keyPath: "id",
                autoIncrement: false
              });
            }

            if (!db.objectStoreNames.contains(AVATAR_DB_STORE)) {
              db.createObjectStore(AVATAR_DB_STORE, {
                keyPath: "id",
                autoIncrement: false
              });
            }

            const contactsStore = transaction.objectStore(CONTACTS_DB_STORE);
            if (!contactsStore.indexNames.contains(INDEX_IS_GLOBAL)) {
              contactsStore.createIndex(INDEX_IS_GLOBAL, "is_global");
            }
            if (!contactsStore.indexNames.contains(INDEX_BY_DELETED_AT)) {
              contactsStore.createIndex(INDEX_BY_DELETED_AT, "deleted_at");
            }
            if (!contactsStore.indexNames.contains(INDEX_BY_PENDING)) {
              contactsStore.createIndex(INDEX_BY_PENDING, "pending");
            }

            if (!contactsStore.indexNames.contains(INDEX_BY_GLOBAL_DELETED)) {
              contactsStore.createIndex(INDEX_BY_GLOBAL_DELETED, ["is_global", "deleted_at"], { unique: false });
            }
            if (!contactsStore.indexNames.contains(INDEX_BY_JID)) {
              contactsStore.createIndex(INDEX_BY_JID, "jid");
            }
            if (!contactsStore.indexNames.contains(INDEX_BY_CONTACT_LISTS)) {
              contactsStore.createIndex(INDEX_BY_CONTACT_LISTS, "contact_list_ids", { multiEntry: true });
            }

            if (!db.objectStoreNames.contains(CONTACT_GROUP_DB_STORE)) {
              const groupStore = db.createObjectStore(CONTACT_GROUP_DB_STORE, {
                keyPath: "id",
                autoIncrement: false
              });
              groupStore.createIndex(INDEX_BY_NAME, "name");
            }

            if (!db.objectStoreNames.contains(PENDING_OP_STORE)) {
              const pendingOpStore = db.createObjectStore(PENDING_OP_STORE, {
                keyPath: "opid",
                autoIncrement: true
              });

              pendingOpStore.createIndex(INDEX_BY_CONTACT_ID, "id", { multiEntry: true });
              pendingOpStore.createIndex(INDEX_BY_TYPE, "type", { multiEntry: true });

            } else {
              const pendingOpStore = transaction.objectStore(PENDING_OP_STORE);
              if (pendingOpStore.indexNames.contains(INDEX_BY_CONTACT_ID)) {
                pendingOpStore.createIndex(INDEX_BY_CONTACT_ID, "id", { multiEntry: true });
                pendingOpStore.createIndex(INDEX_BY_TYPE, "type", { multiEntry: true });
              }
            }


          }
          console.log("[IndexedDBService][idbContext][upgrade] done");
        },
        blocked() {
          console.log("[IndexedDBService][idbContext][blocked]");
        },
        blocking() {
          console.log("[IndexedDBService][idbContext][blocking]");
        }
      });
    }

    deleteDB(): Observable<any> {
      const response = new Subject<any>();

      deleteDB(this.dbName).then(res => {
        response.next(res);
      });

      return response.asObservable().pipe(take(1));
    }

    clearDB(): Observable<any> {
      console.info("[IndexedDBService][clearDB]");

      const response = new Subject<any>();

      this.idbContext().then(db => {
        Promise.all([
          db.clear(CONTACTS_DB_STORE),
        ]).then(result => {
          console.info("[IndexedDBService][clearDB] result = ", result);
        }).catch(error => {
          console.error("[IndexedDBService][clearDB] error = ", error);
          response.error(error);
        });
      });

      return response.asObservable().pipe(take(1));
    }

    fetchContactsByEmail(email): Observable<any> {
      const response = new Subject<any>();
      this.idbContext().then(db => {
        const tx = db.transaction(CONTACTS_DB_STORE, "readonly");
        tx.store.getAll().then(result => {
          const foundObj = result.find(obj => obj.email === email);
          if (foundObj) {
            let name = ""
            name = foundObj.first_name;
            if(foundObj && foundObj.middle_name) name = name + " " + foundObj.middle_name;
            if(foundObj && foundObj.last_name) name = name + " " + foundObj.last_name;
            response.next(name);
          }
        }).catch(error => {
          response.error(error);
        });
      });

      return response.asObservable().pipe(take(1));
  }
}