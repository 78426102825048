
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Subject } from "rxjs";
import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, NgZone, ChangeDetectorRef, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { Broadcaster } from "src/app/common/providers/broadcaster.service";
import { BroadcastKeys } from "src/app/common/enums/broadcast.enum";
import { distinctUntilChanged, takeUntil, take } from "rxjs/operators";
import { Contact } from "src/app/common/models";
import { CreateContactDialogComponent } from "../create-contact-dialog/create-contact-dialog.component";
import { environment } from "src/environments/environment";
import { ToastService } from "src/app/common/service/tost.service";
import { ContactService } from "src/app/common/service/contact-service";
import { CommonUtil } from "src/app/common/utils/common.utils";
import { SearchResponse } from "src/app/common/models/search-item";
import { ContactRepository } from "../../repository/contact.repository";
import { ContactRootState } from "../../store";
import { RootState, getFederatedApps, getLoggedInUserContact, getUserProfile, getOnlineStatus } from "src/app/reducers";
import { Store } from "@ngrx/store";
import { ConfirmationDialogComponent } from "src/app/shared/components/confirmation-dialog/confirmation-dialog.component";
import { ConfirmDialogType } from "src/app/common/models/dialog.model";
import { RecentActivityDialogComponent } from "../recent-activity-dialog/recent-activity-dialog.component";
import { ContactUtils } from "src/app/common/utils/contacts-utils";
import { DatabaseService } from "src/app/common/service/db/database.service";

@Component({
    selector: "vp-contact-detail-dialog",
    templateUrl: "./contact-detail-dialog.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactDetailDialogComponent implements OnInit, OnDestroy {

    private isAlive$ = new Subject<boolean>();
    contact: Contact = null;
    supportedApps = ["talk", "mail", "task"];
    searchParams: any = {
        q: "*:*",
        sort: "created_dt desc",
        start: 0,
        apps: this.supportedApps.join(","),
        limit: 20
    };
    searchResponse: SearchResponse;
    fedrateApps: any;
    isShowSendEmailOption: boolean = true;
    isShowTalkOptions: boolean = true;
    isShowTaskOption: boolean = true;
    showMoreDetail: boolean = false;
    backgroundImageStyle: any = {};
    isShowTicketOption: boolean = true;
    currentUser: Contact;
    isCordova: boolean = false;
    selectedTab: any = 0;
    swipeCoord: any;
    swipeTime: number = 0;
    recentActivitySelectedTab: any = 0;
    recentActivityswipeCoord: any;
    recentActivityswipeTime: number = 0;
    showHistoryButtonTop: boolean = false;
    isShowCalendarOption: boolean = true;
    lang: string = '';
    isOnline: boolean;
    isOnMobile: boolean;

    constructor(
        public dialogRef: MatDialogRef<ContactDetailDialogComponent>,
        private broadcaster: Broadcaster,
        private ngZone: NgZone,
        private changeDetectorRef: ChangeDetectorRef,
        private matDialog: MatDialog,
        private toastService: ToastService,
        private contactService: ContactService,
        private databaseService: DatabaseService,
        private contactRepository: ContactRepository,
        private store: Store<ContactRootState | RootState>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        if (this.data.contact) {
            this.contact = this.data.contact;
            console.log("[contac]: ", this.contact);
            this.changeDetectorRef.markForCheck();
        }

        this.store.select(getOnlineStatus).pipe(distinctUntilChanged(), takeUntil(this.isAlive$)).subscribe(res => {
            this.isOnline = res;
        });
        this.isOnMobile = CommonUtil.isOnMobileDevice();

        this.store.select(getFederatedApps).pipe(takeUntil(this.isAlive$)).subscribe(apps => {
            console.log("[getFederatedApps]", apps);
            this.fedrateApps = apps;
            const isMailAppAvailable = this.fedrateApps.filter(fa => fa.name === "vncmail")[0];
            if (!!isMailAppAvailable) {
                this.isShowSendEmailOption = true;
            } else {
                this.isShowSendEmailOption = false;
            }
            const isTalkAppAvailable = this.fedrateApps.filter(fa => fa.name === "vnctalk")[0];
            if (!!isTalkAppAvailable) {
                this.isShowTalkOptions = true;
            } else {
                this.isShowTalkOptions = false;
            }
            const isTaskAppAvailable = this.fedrateApps.filter(fa => fa.name === "vnctask")[0];
            if (!!isTaskAppAvailable) {
                this.isShowTaskOption = true;
                this.checkVNCtaskRights(this.contact);
            } else {
                this.isShowTaskOption = false;
            }
            const isProjectAppAvailable = this.fedrateApps.filter(fa => fa.name === "vncproject")[0];
            if (!!isProjectAppAvailable) {
                this.isShowTicketOption = true;
                this.checkVNCprojectRights(this.contact);
            } else {
                this.isShowTicketOption = false;
            }
            const isCalendarAppAvailable = this.fedrateApps.filter(fa => fa.name === "vnccalendar")[0];
            if (!!isCalendarAppAvailable) {
                this.isShowCalendarOption = true;
            } else {
                this.isShowCalendarOption = false;
            }
            this.changeDetectorRef.markForCheck();
        });
        const mail = !!this.contact.emails ? this.contact.emails[0]?.email : "";
        const email = !!this.contact.jid ? this.contact.jid : mail;
        this.databaseService.getAvatarByEmail(mail).pipe(take(1)).subscribe(avt => {
            if (!!avt && !!avt.origUrl) {
                this.backgroundImageStyle = {
                    "background": "url(" + avt.origUrl + ") center center / cover no-repeat",
                    "filter": "blur(5px)",
                    "-webkit-filter": "blur(5px)"
                };
                this.changeDetectorRef.markForCheck();
            } else {
                if (this.contact.avatar) {
                    this.backgroundImageStyle = {
                      "background": "url(" + this.contact.avatar + ") center center / cover no-repeat",
                      "filter": "blur(5px)",
                      "-webkit-filter": "blur(5px)"
                    };
                    this.changeDetectorRef.markForCheck();
                }
            }
        });
        this.store.select(getLoggedInUserContact).pipe(take(1)).subscribe(res => {
            if (!!res && res !== null) {
              this.currentUser = res;
            }
            this.changeDetectorRef.markForCheck();
        });
        this.store.select(getUserProfile).pipe(takeUntil(this.isAlive$)).subscribe(res => {
            if (!!res && res !== null) {
                this.lang = res?.language;
            }
            this.changeDetectorRef.markForCheck();
        });
        this.isCordova = environment.isCordova;
    }

    ngOnInit() {
        this.broadcaster.on<any>(BroadcastKeys.HIDE_CONTACT_DETAIL_DIALOG).pipe(takeUntil(this.isAlive$)).subscribe(res => {
            this.ngZone.run(() => {
                this.close();
            });
        });
        this.broadcaster.on<any>(BroadcastKeys.FAVORITE_UNFAVORITE_CONTACT_RESPONSE).pipe(takeUntil(this.isAlive$)).subscribe(res => {
            if (res) {
                this.contact.favorite = true;
            } else {
                this.contact.favorite = false;
            }
            this.changeDetectorRef.markForCheck();
        });
        const supportApp = this.supportedApps.join(",");
        this.callSearchActivity(supportApp);
    }

    checkVNCtaskRights(contact: Contact) {
        if (contact && contact.username && contact.products && contact.products.length > 0) {
            const isTaskEnabled = contact.products.filter(fa => fa.name === "vnctask")[0];
            if (isTaskEnabled) {
                this.isShowTaskOption = true;
            } else {
                this.isShowTaskOption = false;
            }
        } else {
            this.isShowTaskOption = false;
        }
    }

    checkVNCprojectRights(contact: Contact) {
        if (contact && contact.username && contact.products && contact.products.length > 0) {
            const isProjectEnabled = contact.products.filter(fa => fa.name === "vncproject")[0];
            if (isProjectEnabled) {
                this.isShowTicketOption = true;
            } else {
                this.isShowTicketOption = false;
            }
        } else {
            this.isShowTicketOption = false;
        }
    }

    close(): void {
        this.dialogRef.close();
    }

    editContact(): void {
        this.matDialog.open(CreateContactDialogComponent, {
            maxWidth: "100%",
            autoFocus: false,
            panelClass: "create_contactplus_dialog",
            disableClose: true,
            data: { isEdit: true, contact: this.contact }
        });
        this.close();
    }

    ngOnDestroy() {
        this.isAlive$.next(false);
        this.isAlive$.complete();
    }

    startAudioCall(): void {
        this.contactRepository.makeTalkAudioChatVideoOperation([this.contact], "audio-call", "group-audio");
    }

    startVideoCall(): void {
        this.contactRepository.makeTalkAudioChatVideoOperation([this.contact], "video-call", "group-video");
    }

    startChat(): void {
        this.contactRepository.makeTalkAudioChatVideoOperation([this.contact], "start-chat", "group-chat");
    }

    shareScreen(): void {
        this.contactRepository.makeTalkAudioChatVideoOperation([this.contact], "screen-share", "group-screen");
    }

    showFeatureUnderDevelopmentToast(): void {
        this.toastService.show("FEATURE_NEXT_VERSION_LBL");
    }

    openAddressLocation(address: any): void {
        let addressData: string[] = [];
        address.street1 ? addressData.push(address.street1) : "";
        address.city ? addressData.push(address.city) : "";
        address.state ? addressData.push(address.state) : "";
        address.country_code ? addressData.push(address.country_code) : "";
        this.contactService.getlatlng(addressData.toString()).subscribe(res => {
            if (res.results.length > 0) {
              let loc = res.results[0].geometry.location;
              this.openMap(loc, addressData.toString());
            } else {
                this.toastService.show("NO_LOCATION_FOUND");
            }
        });
    }

    openMap(location: any, address: any): void {
        console.log("Location: ", location);
        let latLon = location.lat + "," + location.lng;
        if (environment.isCordova) {
            if (CommonUtil.isOnAndroid()) {
                window.open("geo:0,0?q=" + latLon + "(" + address + ")", "_system");
            } else if (CommonUtil.isOnIOS()) {
                cordova.InAppBrowser.open("http://maps.apple.com/?q=" + address, "_system");
            }
        } else {
            window.open(this.contactService.getDesktopMapUrl(latLon));
        }
    }

    callSearchActivity(type_s: string, type_p?: string): void {
        if (!!this.searchResponse) {
            this.searchResponse.docs = [];
            this.changeDetectorRef.markForCheck();
        }
        if (this.contact.emails && this.contact.emails.length > 0) {
            this.searchParams.from_s = (this.contact.jid) ? this.contact.jid : this.contact.emails[0].email;
            if (type_p) {
                this.searchParams.type_p = type_p;
            }
            if (type_s) {
                this.searchParams.type_s = type_s;
            }
            this.contactRepository.searchDocs(this.searchParams).pipe(take(1)).subscribe(docs => {
                console.log("Recent activity for selected contact", docs);
                this.searchResponse = docs;
                console.log("[searchResponse]", this.searchResponse);
                this.changeDetectorRef.markForCheck();
            });
        }
    }

    onSelectedTabChangeActivity(ev: any) {
        if (ev.index === 1) {
            this.callSearchActivity("talk", "chat");
        } else if (ev.index === 3) {
            this.callSearchActivity("mail");
        } else if (ev.index === 4) {
            this.callSearchActivity("issue", "task");
        } else if (ev.index === 0) {
            const supportApp = this.supportedApps.join(",");
            this.callSearchActivity("*");
        }
    }

    startMail(): void {
        this.contactRepository.sendEmail([this.contact]);
    }

    createTask(): void {
        this.contactRepository.createTask([this.contact]);
    }

    showMoreLess(value: boolean): void {
        this.showMoreDetail = value;
        this.changeDetectorRef.markForCheck();
    }

    exportContact(): void {
        if (!this.isOnline) {
            this.toastService.show("APP_OFFLINE");
            return;
        }

        const filter = "?all=1&id=" + this.contact.id;
        this.contactRepository.exportContacts(filter, "", "csv");
    }

    printContact(): void {
        if (!this.isOnline) {
            this.toastService.show("APP_OFFLINE");
            return;
        }

        this.contactRepository.printContacts([this.contact]);
    }

    deleteContact(): void {
        const dlg = this.matDialog.open(ConfirmationDialogComponent, {
            maxWidth: "100%",
            autoFocus: false,
            panelClass: "confirm_contactplus_dialog",
            data : { dialogType: ConfirmDialogType.DELETE_CONTACT, message_title: "DELETE", message_body: "CONFIRM_DELETE_CONTACT"}
        });
        dlg.afterClosed().pipe(take(1)).subscribe(res => {
            if (!!res && res.confirmation) {
                if (res.confirmation === "yes") {
                    this.contactRepository.deleteContact([this.contact]);
                }
            }
        });
    }

    shareContact(): void {
        this.contactRepository.shareContactVcard([this.contact]);
    }

    createTicket(): void {
        this.contactRepository.createTicket([this.contact]);
    }

    getBgAvatarColor(jid) {
        if (this.currentUser && ( this.currentUser.jid === jid || this.currentUser.emails[0] === jid )) {
            return this.currentUser.bgAvatarColor;
        } else {
            return this.contact.bgAvatarColor;
        }
    }

    getAvatarUrl(jid) {
        if (this.currentUser && ( this.currentUser.jid === jid || this.currentUser.emails[0] === jid )) {
            return this.currentUser.avatar;
        } else {
            return this.contact.avatar;
        }
    }

    composeEmail(email: string): void {
        this.contactRepository.sendMailToEmail(email);
    }

    callPhoneNumber(phoneNumber: string): void {
        this.contactRepository.callPhoneNumber(phoneNumber);
    }

    composeOnEmail(email: string): void {
        this.contactRepository.composeOnEmail(email);
    }

    parseAddressLocation(address: any): void {
        let addressData: string[] = [];
        address.street1 ? addressData.push(address.street1) : "";
        address.city ? addressData.push(address.city) : "";
        address.state ? addressData.push(address.state) : "";
        address.country ? addressData.push(address.country) : "";
        address.postcode ? addressData.push(address.postcode) : "";
        address.country_code ? addressData.push(address.country_code) : "";
        const addressItem = addressData.toString();
        console.log("[parseAddress]: ", addressItem);
        this.contactRepository.openAddressOnMap(addressItem);
    }

    openRecentActivity(item) {
        if ( item.type === "talk") {
            if ( item.chatType === "chat") {
                this.contactRepository.jumpToChat(this.contact, item.talkId, item.createdDt);
            } else {
                this.underDevelopment();
            }
        } else if ( item.type === "mail") {
            if (this.contactRepository.restrictOpenMail(item)) {
                this.contactRepository.openMail(item.id);
            }
        } else if (item.type === "task") {
            this.contactRepository.openTask(item.id);
        } else {
            this.underDevelopment();
        }
    }

    underDevelopment() {
        this.toastService.show("COMING_SOON");
    }

    swipe (e: TouchEvent, when: string): void {
        const coord: [number, number] = [
            e.changedTouches[0].clientX,
            e.changedTouches[0].clientY
        ];
        const time = new Date().getTime();
        if (when === "start") {
            this.swipeCoord = coord;
            this.swipeTime = time;
        } else if (when === "end") {
            const direction = [coord[0] - this.swipeCoord[0], coord[1] - this.swipeCoord[1]];
            const duration = time - this.swipeTime;
            if (duration < 1000 && Math.abs(direction[0]) > 30 && Math.abs(direction[0]) > Math.abs(direction[1] * 3)) {
                const swipe = direction[0] < 0 ? "next" : "previous";
                console.info(swipe);
                if (swipe === "next") {
                    const isFirst = this.selectedTab === 0;
                    if (this.selectedTab <= 1) {
                        this.selectedTab = isFirst ? 1 : this.selectedTab + 1;
                    }
                } else if (swipe === "previous") {
                    const isLast = this.selectedTab === 2;
                    if (this.selectedTab >= 1) {
                        this.selectedTab = this.selectedTab - 1;
                    }
                }
            }
        }
        this.changeDetectorRef.markForCheck();
    }

    openRecentActivityDialog(): void {
        this.matDialog.open(RecentActivityDialogComponent, {
            maxWidth: "100%",
            autoFocus: false,
            data: { contact: this.contact },
            panelClass: "contact_recent_activity_dialog"
        });
    }

    markAsFavoriteContact(markFavorite: boolean, contact: Contact) {
        this.contactRepository.markContactFavorite(markFavorite, [contact]);
    }

    scrollingPage(ev: any): void {
        if (CommonUtil.isOnMobileDevice()) {
            const mobileDetail = <HTMLElement> document.querySelector(".mobile-detail");
            if (mobileDetail !== null) {
                if ((ev.target.scrollTop + 60) > mobileDetail.offsetTop) {
                    mobileDetail.classList.add("sticky-header");
                    this.showHistoryButtonTop = true;
                    this.changeDetectorRef.markForCheck();
                    const dialotBody = <HTMLElement> document.querySelector(".contact__dialog-body");
                    if (dialotBody !== null) {
                        setTimeout(() => {
                            dialotBody.scrollTop += 1;
                        }, 100);
                    }
                }
            }
        }
    }


    scrollingDialog(ev: any): void {
        if (CommonUtil.isOnMobileDevice()) {
            const tabHeader = <HTMLElement> document.querySelector(".mobile-detail .mat-tab-header");
            if (tabHeader !== null) {
                tabHeader.style.position = "fixed";
                tabHeader.style.top = "48px";
                tabHeader.style.background = "white";
                tabHeader.style.zIndex = "9999";
            }
            if (ev.target.scrollTop === 0) {
                this.showHistoryButtonTop = false;
                this.changeDetectorRef.markForCheck();
                const mobileDetail = <HTMLElement> document.querySelector(".mobile-detail");
                if (mobileDetail !== null) {
                    mobileDetail.classList.remove("sticky-header");
                    const tabHeader = <HTMLElement> document.querySelector(".mobile-detail .mat-tab-header");
                    if (tabHeader !== null) {
                        tabHeader.removeAttribute("style");
                    }
                }
            }
        }
    }

    composeEvent(): void {
        this.contactRepository.composeCalendarEvent([this.contact]);
    }

    getAddress(address: any): string {
        return ContactUtils.getAddress(address);
    }

    openUrlNewTab(url: string): void {
        this.contactRepository.openUrlNewTab(url);
    }
}
