
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { RootState } from "../../../reducers";
import { createFeatureSelector, createSelector } from "@ngrx/store";
import * as fromContactFolder from "./contact-folder.reducer";
import { contactFolderAdapter } from "./contact-folder.reducer";
import * as fromContactTag from "./contact-tag.reducer";
import { contactTagAdapter } from "./contact-tag.reducer";
import * as fromTrashContact from "./trash-contacts.reducers";
import * as fromListContact from "./list-contacts.reducers";
import * as fromTagContact from "./tag-contacts.reducers";
import * as fromGroupContact from "./group-contacts.reducers";
import * as fromFrequentlyContact from "./frequently-contacts.reducers";
import {
  contactAdapter,
   contactReducer,
   ContactState,
  _getIsContactsLoading,
  _getIsContactsLoaded,
  _getCurrentOffset,
  _getIsNextPageLoading,
  _getIsNextPageLoaded,
  _getIsSearchMode,
  _getSearchParams, 
  _getIsMoreContacts
} from "./contacts.reducers";

import {
  contactTrashAdapter,
  _getIsTrashContactsLoading,
  _getIsTrashContactsLoaded,
  _getIsTrashNextPageLoading,
  _getIsTrashNextPageLoaded,
  _getCurrentTrashOffset,
  _getIsTrashMoreContacts
} from "./trash-contacts.reducers";

import {
  contactListAdapter,
  _getIsListContactsLoading,
  _getIsListContactsLoaded,
  _getIsListNextPageLoading,
  _getIsListNextPageLoaded,
  _getCurrentListOffset,
  _getIsListMoreContacts
} from "./list-contacts.reducers";

import {
  tagContactAdapter,
  _getIsTagContactsLoading,
  _getIsTagContactsLoaded,
  _getIsTagNextPageLoading,
  _getIsTagNextPageLoaded,
  _getCurrentTagOffset,
  _getIsTagMoreContacts
} from "./tag-contacts.reducers";

import {
  contactGroupAdapter,
  _getIsGroupContactsLoading,
  _getIsGroupContactsLoaded,
  _getIsGroupNextPageLoading,
  _getIsGroupNextPageLoaded,
  _getCurrentGroupOffset,
  _getIsGroupMoreContacts
} from "./group-contacts.reducers";

import {
  frequentlyContactAdapter,
  _getIsFrequntlyContactsLoading,
  _getIsFrequntlyContactsLoaded,
  _getIsFrequntlyNextPageLoading,
  _getIsFrequntlyNextPageLoaded,
  _getCurrentFrequntlyOffset,
  _getIsFrequntlyMoreContacts
} from "./frequently-contacts.reducers";


export interface ContactRootState extends RootState {
  contact: State;
}

export const getContactRootState = createFeatureSelector<State>("contact");

export interface State {
  contact: ContactState;
  contactFolder: fromContactFolder.ContactFolderState;
  contactTag: fromContactTag.ContactTagState;
  trashContact: fromTrashContact.ContactTrashState;
  listContact: fromListContact.ContactListState;
  tagContact: fromTagContact.ContactTagState;
  groupContact: fromGroupContact.ContactGroupState;
  frequentlyContact: fromFrequentlyContact.ContactFrequntlyState;
}

export const contactRootReducer = {
  contact: contactReducer,
  contactFolder: fromContactFolder.contactFolderReducer,
  contactTag: fromContactTag.contactTagReducer,
  trashContact: fromTrashContact.trashContactReducer,
  listContact: fromListContact.listContactReducer,
  tagContact: fromTagContact.tagContactReducer,
  groupContact: fromGroupContact.groupContactReducer,
  frequentlyContact: fromFrequentlyContact.frequentlyContactReducer
};

export const getContactState = createSelector(
  getContactRootState,
  state => state.contact
);

export const {
  selectIds: getContactIds,
  selectEntities: getContactEntities,
  selectAll: getAllContact,
  selectTotal: getTotalContact,
} = contactAdapter.getSelectors(getContactState);

export const getContacts = getAllContact;

export const getIsContactsLoading = createSelector(
  getContactState,
  _getIsContactsLoading
);

export const getIsContactsLoaded = createSelector(
  getContactState,
  _getIsContactsLoaded
);

export const getIsContactsNextPageLoading = createSelector(
  getContactState,
  _getIsNextPageLoading
);

export const getIsContactssNextPageLoaded  = createSelector(
  getContactState,
  _getIsNextPageLoaded
);

export const getContactsCurrentPageOffset = createSelector(
  getContactState,
  _getCurrentOffset
);

export const getSearchMode = createSelector(
  getContactState,
  _getIsSearchMode
);

export const getIsMoreContacts = createSelector(
  getContactState,
  _getIsMoreContacts
);

export const getSearchParams = createSelector(
  getContactState,
  _getSearchParams
);

export const getContactFolderState = createSelector(
  getContactRootState,
  state => state.contactFolder
);

export const {
  selectIds: getContactFolderIds,
  selectEntities: getContactFolderEntities,
  selectAll: getAllContactFolder,
  selectTotal: getTotalContactFolder,
} = contactFolderAdapter.getSelectors(getContactFolderState);
export const getContactFolders = getAllContactFolder;

export const getContactTagState = createSelector(
  getContactRootState,
  state => state.contactTag
);

export const {
  selectIds: getContactTagIds,
  selectEntities: getContactTagEntities,
  selectAll: getAllContactTag,
  selectTotal: getTotalContactTag,
} = contactTagAdapter.getSelectors(getContactTagState);
export const getContactTags = getAllContactTag;

/* Trash Contact */

export const getTrashContactState = createSelector(
  getContactRootState,
  state => state.trashContact
);

export const {
  selectIds: getTrashContactIds,
  selectEntities: getTrashContactEntities,
  selectAll: getAllTrashContact,
  selectTotal: getTotalTrashContact,
} = contactTrashAdapter.getSelectors(getTrashContactState);

export const getTrashContacts = getAllTrashContact;

export const getIsTrashContactsLoading = createSelector(
  getTrashContactState,
  _getIsTrashContactsLoading
);

export const getIsTrashContactsLoaded = createSelector(
  getTrashContactState,
  _getIsTrashContactsLoaded
);

export const getIsTrashContactsNextPageLoading = createSelector(
  getTrashContactState,
  _getIsTrashNextPageLoading
);

export const getIsTrashContactssNextPageLoaded  = createSelector(
  getTrashContactState,
  _getIsTrashNextPageLoaded
);

export const getTrashContactsCurrentPageOffset = createSelector(
  getTrashContactState,
  _getCurrentTrashOffset
);

export const getIsMoreTrashContacts = createSelector(
  getTrashContactState,
  _getIsTrashMoreContacts
);

/* List Contact */

export const getListContactState = createSelector(
  getContactRootState,
  state => state.listContact
);

export const {
  selectIds: getListContactIds,
  selectEntities: getListContactEntities,
  selectAll: getAllListContact,
  selectTotal: getTotalListContact,
} = contactListAdapter.getSelectors(getListContactState);

export const getListContacts = getAllListContact;

export const getIsListContactsLoading = createSelector(
  getListContactState,
  _getIsListContactsLoading
);

export const getIsListContactsLoaded = createSelector(
  getListContactState,
  _getIsListContactsLoaded
);

export const getIsListContactsNextPageLoading = createSelector(
  getListContactState,
  _getIsListNextPageLoading
);

export const getIsListContactssNextPageLoaded  = createSelector(
  getListContactState,
  _getIsListNextPageLoaded
);

export const getListContactsCurrentPageOffset = createSelector(
  getListContactState,
  _getCurrentListOffset
);

export const getIsMoreListContacts = createSelector(
  getListContactState,
  _getIsListMoreContacts
);

/* Tag Contact */

export const getTagContactState = createSelector(
  getContactRootState,
  state => state.tagContact
);

export const {
  selectIds: getTagContactIds,
  selectEntities: getTagContactEntities,
  selectAll: getAllTagContact,
  selectTotal: getTotalTagContact,
} = tagContactAdapter.getSelectors(getTagContactState);

export const getTagContacts = getAllTagContact;

export const getIsTagContactsLoading = createSelector(
  getTagContactState,
  _getIsTagContactsLoading
);

export const getIsTagContactsLoaded = createSelector(
  getTagContactState,
  _getIsTagContactsLoaded
);

export const getIsTagContactsNextPageLoading = createSelector(
  getTagContactState,
  _getIsTagNextPageLoading
);

export const getIsTagContactssNextPageLoaded  = createSelector(
  getTagContactState,
  _getIsTagNextPageLoaded
);

export const getTagContactsCurrentPageOffset = createSelector(
  getTagContactState,
  _getCurrentTagOffset
);

export const getIsMoreTagContacts = createSelector(
  getTagContactState,
  _getIsTagMoreContacts
);

/* Group Contact */

export const getGroupContactState = createSelector(
  getContactRootState,
  state => state.groupContact
);

export const {
  selectIds: getGroupContactIds,
  selectEntities: getGroupContactEntities,
  selectAll: getAllGroupContact,
  selectTotal: getTotalGroupContact,
} = contactGroupAdapter.getSelectors(getGroupContactState);

export const getGroupContacts = getAllGroupContact;

export const getIsGroupContactsLoading = createSelector(
  getGroupContactState,
  _getIsGroupContactsLoading
);

export const getIsGroupContactsLoaded = createSelector(
  getGroupContactState,
  _getIsGroupContactsLoaded
);

export const getIsGroupContactsNextPageLoading = createSelector(
  getGroupContactState,
  _getIsGroupNextPageLoading
);

export const getIsGroupContactssNextPageLoaded  = createSelector(
  getGroupContactState,
  _getIsGroupNextPageLoaded
);

export const getGroupContactsCurrentPageOffset = createSelector(
  getGroupContactState,
  _getCurrentGroupOffset
);

export const getIsMoreGroupContacts = createSelector(
  getGroupContactState,
  _getIsGroupMoreContacts
);

/* Frequently Contact */

export const getFrequentlyContactState = createSelector(
  getContactRootState,
  state => state.frequentlyContact
);

export const {
  selectIds: getFrequentlyContactIds,
  selectEntities: getFrequentlyContactEntities,
  selectAll: getAllFrequentlyContact,
  selectTotal: getTotalFrequentlyContact,
} = frequentlyContactAdapter.getSelectors(getFrequentlyContactState);

export const getFrequentlyContacts = getAllFrequentlyContact;

export const getIsFrequentlyContactsLoading = createSelector(
  getFrequentlyContactState,
  _getIsFrequntlyContactsLoading
);

export const getIsFrequentlyContactsLoaded = createSelector(
  getFrequentlyContactState,
  _getIsFrequntlyContactsLoaded
);

export const getIsFrequentlyContactsNextPageLoading = createSelector(
  getFrequentlyContactState,
  _getIsFrequntlyNextPageLoading
);

export const getIsFrequentlyContactssNextPageLoaded  = createSelector(
  getFrequentlyContactState,
  _getIsFrequntlyNextPageLoaded
);

export const getFrequentlyContactsCurrentPageOffset = createSelector(
  getFrequentlyContactState,
  _getCurrentFrequntlyOffset
);

export const getIsMoreFrequentlyContacts = createSelector(
  getFrequentlyContactState,
  _getIsFrequntlyMoreContacts
);
